import React from "react";
import { AutoComplete, Form, Col } from "antd";
import PropTypes from "prop-types";
const { Item } = Form;
const size = "large";

const FormAutocomplete = props => {
  return (
    <Col span={8}>
      <Item label={props.label} required={props.isRequired}>
        <AutoComplete
          size={size}
          name={props.id}
          onBlur={props.handleBlur}
          onChange={value => {
            props.setFieldValue(props.id, value);
          }}
          placeholder={props.placeholder}
          value={props.value}
          dataSource={props.data}
        />
      </Item>
    </Col>
  );
};

FormAutocomplete.propTypes = {
  id: PropTypes.string.isRequired,
  errorMessage: PropTypes.string,
  placeholder: PropTypes.string,
  data: PropTypes.array
};

export default FormAutocomplete;
