const Strings = {
  appName: "MI Device Tracker",
  companyEmail: "mobisoftinfotech.com",
  signInWithGmail: "Sign In with Google",
  claimDevice: "Claim Device",
  relinquishDevice: "Relinquish Device",
  claim: "Claim",
  relinquishAt: "Relinquish At",
  home: "Home",
  list: "Devices",
  setting: "Setting",
  profile: "Profile",
  users: "Users",
  currentDevice: "Current Device",
  deviceName: "Device Name",
  deviceId: "Device Id",
  osVersion: "OS Version",
  deviceOs: "Device OS",
  os: "Select OS",
  update: "Update",
  deviceList: "Device List",
  deviceTracker: "Device Tracker",
  admin: "Admin",
  filter: "Filter",
  downloadReport: "Download Report",
  apply: "Apply",
  availableDevices: "Available devices",
  claimedDevices: "Claimed devices",
  allocatedDevices: "Allocated Devices",
  android: "Android",
  ios: "iOS",
  close: "Close",
  clear: "Clear",
  error: "Error",
  success: "Success",
  ok: "Ok",
  yes: "Yes",
  no: "No",
  detail: "Detail",
  history: "History",
  cancel: "Cancel",
  signIn: "SignIn",
  signUp: "SignUp",
  email: "Email",
  password: "Password",
  employeeId: "Employee Id",
  confirmPassword: "Confirm Password",
  emailPlaceHolder: "example@mobisoftinfotech.com",
  projectNamePlaceHolder: "Enter project name here",
  passwordPlaceholder: "Enter password here",
  empIdPlaceholder: "Enter your employee Id",
  confirmPasswordPlaceholder: "Enter confirm password here",
  passwordToShort: "Enter minimum 6 characters",
  name: "Name",
  namePlaceholder: "Enter name here",
  addUserSuccess: "User added successfully",
  claimSuccess: "Device claimed successfully",
  updateDevice: "Device updated successfully",
  relinquishSuccess: "Device relinquished successfully",
  deviceNamePlaceholder: "Eg. Samsung J7",
  deviceIdPlaceholder: "Eg. MIAN4",
  deviceOSPlaceholder: "Eg. Android",
  deviceMemoryPlaceholder: "Eg. 4 GB",
  deviceVersionPlaceholder: "Eg. 6.0",
  signOut: "Sign Out",
  signOutWarning: "Are you sure, you want to sign out?",
  deviceNotRegistered:
    "This device is not registered with us, kindly contact the admin to register this device.",
  noDeviceFound: "Devices not found.",
  noUserFound: "User not found.",
  forgotPassword: "Forgot Password?",
  forgotPasswordTitle: "Forgot Password",
  send: "Send",
  passwordResetLinkSuccess: "Password reset link sent successfully.",
  emailValidationError: "Please enter the official email id.",
  passwordValidationError: "Enter minimum 6 characters",
  nameValidationError: "Please enter valid name.",
  passwordBlankError: "Password cannot be blank.",
  projectNameBlankError: "Project name cannot be blank.",
  addUserToAllocate: "Add at least one user",
  confirmPasswordBlankError: "Confirm Password cannot be blank.",
  confirmPasswordNotMatch: "Confirm password do not match.",
  deviceNameValidationError: "Please enter valid device name",
  deviceIdValidationError: "Please enter valid device id",
  deviceVersionValidationError: "Please enter valid device version",
  networkConnectioError: "Please check your internet connection.",
  user: "User",
  delete: "Delete",
  deleteWarning: "Are you sure, you want to delete?",
  downloadWarning: "Are you sure, you want to download report?",
  userUpdateSuccess: "User updated successfully",
  deviceDeletedError:
    "This device may be deleted by admin, please contact to admin",
  userDeleted: "This user may have been deleted, kindly contact the admin.",
  userAdminDeleted:
    "This user may have been deleted or may not have admin access, kindly contact the admin.",
  deviceDeletedSuccess: "Device deleted successfully.",
  inventoryDeletedSuccess: "Inventory deleted successfully.",
  userDeletedSuccess: "User deleted successfully.",
  commonError: "Something went wrong, please try again",
  invalidLoginCredentials: "Email or password is incorrect. Please try again.",
  userNotClaimed: "This device is not claimed, please claim the device.",
  deviceInfo: "Device Info",
  deviceInfoId: "Id ",
  deviceInfoName: "Name ",
  deviceInfoVersion: "OS Version",
  deviceInfoOS: "OS ",
  claimedBy: "Claimed By",
  claimedAt: "Claimed At",
  searchHere: "Search here",
  addUser: "Add User",
  edit: "Edit",
  addInventory: "Add Inventory",
  inventoryList: "Inventory List",
  footerText: "Copyright © 2016 Mobisoft-infotech : All Rights Reserved",
  loginSuccess: "Login successfully",
  addInventorySuccess: "Inventory added successfully",
  updateInventorySuccess: "Inventory updated successfully",
  inventoryName: "Asset Name",
  inventoryType: "Asset Type",
  inventoryTypePlaceholder: "Select asset types",
  inventoryStatusPlaceholder: "Select asset status",
  purchaseTypePlaceholder: "Select purchase type",
  inventoryAvailabilityPlaceholder: "Select asset availability",
  inventoryOS: "Asset OS",
  inventoryId: "Asset ID",
  inventoryInfo: "Asset Info",
  purchaseInfo: "Purchase Info",
  currentUserInfo: "Current User Info",
  purchaseDate: "Purchase Date",
  vendorName: "Vendor Name",
  vendorNamePlaceholder: "Eg. Flifkart",
  modelNumber: "Model Number",
  serialNumber: "Serial Number",
  staticIpAddress: "Static IP Address",
  macAddress: "WI-FI Mac Address",
  imeiNumber: "IMEI Number",
  memorySize: "Memory Size",
  lanMacAddress: "LAN Mac Address",
  issue: "Issue",
  remark: "Remark",
  companyName: "Manufacturer Name",
  insuranceStatus: "Insurance Status",
  clientDevice: "Client Device",
  inventoryStatus: "Inventory status (Active/Inactive)",
  deviceTrackerStatus: "Device tracker status",
  insured: "Insured",
  status: "Status",
  inventoryInfoNotFound: "Inventory information not found",
  inventoryHistoryNotFound: "Inventory history not found",
  notInsured: "Not Insured",
  save: "Save",
  inventoryFieldError: "Please insert required field",
  statusActive: "Active",
  statusInActive: "Inactive",
  allocateDevice: "Allocate",
  addHistory: "Add History",
  inventoryHistory: "History",
  deallocate: "Deallocate",
  allocatedAt: "Allocated at",
  deallocatedAt: "Deallocated at",
  allocateInventorySuccess: "Inventory allocated successfully",
  deallocateInventorySuccess: "Inventory deallocated successfully",
  installed:"Installed",
  unInstalled:"Not Installed",
  projectName: "Project/User Name",
  purchaseType: "Purchase Type",
  allocateWarning: "This asset is allocated already, please deallocate first and then allocate it.",
  returnDate: "Return date",
  macAddressError: "This Mac address is already exist, please enter another mac address",
  manufacturerPlaceholder: "Eg. Samsung",
  total:'Total:',
  lanOrWifiMacAddress:'Lan or WIFI Mac Address'
};

export default Strings;
