import React, { Component } from "react";
import {
  fetchInventoryDetails,
  toggleAllocateInventory,
  fetchUsers,
  allocateInventory,
  clearMessages,
  deleteInventory,
  deAllocateInventory,
  removeListener
} from "../actions/InventoryDetailActions";
import PropTypes from "prop-types";
import FirebaseDBManager from "../firebase/FirebaseDBManager";
import { connect } from "react-redux";
import InventoryInfo from "../components/layouts/InventoryInfo";
import PurchaseInfo from "../components/layouts/PurchaseInfo";
import CurrentUserInfo from "../components/layouts/CurrentUserInfo";
import AllocateInventoryDialog from "../components/dialogs/AllocateInventoryDialog";
import { Col, Card, Button } from "antd";
import Colors from "../utils/Colors";
import Strings from "../utils/Strings";
import { Link } from "react-router-dom";
import {
  showSuccessAlert,
  showErrorAlert,
  showDeleteWarning,
  showErrorMessage,
  showSuccessMessage
} from "../utils/ShowAlertDialog";
const size = "large";

class InventoryDetails extends Component {
  constructor() {
    super();
    this.allocateInventory = this.allocateInventory.bind(this);
  }
  componentDidMount() {
    FirebaseDBManager.init();

    const {
      match: { params }
    } = this.props;
    this.props.fetchInventoryDetails(params.inventoryId);
    this.props.fetchUsers();
  }

  componentWillUnmount() {
    try {
      removeListener(this.props.inventory.id);
    } catch (error) {}
  }

  allocateInventory(allocationDetail, actions) {
    const {
      match: { params }
    } = this.props;
    this.props.allocateInventory(allocationDetail, params.inventoryId, actions);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.inventoryDeletedSuccess) {
      showSuccessMessage(nextProps.inventoryDeletedSuccess);
      nextProps.clearMessages();
      this.props.history.goBack();
    }

    if (nextProps.inventoryDeletedError) {
      showErrorAlert(nextProps.inventoryDeletedError, () => {
        nextProps.clearMessages();
      });
    }

    if (nextProps.inventoryError) {
      showErrorAlert(nextProps.inventoryError, () => {
        nextProps.clearMessages();
        this.props.history.goBack();
      });
    }
    if (nextProps.deallocateInventorySuccess) {
      showSuccessAlert(nextProps.deallocateInventorySuccess, () => {
        nextProps.clearMessages();
      });
    }
    if (nextProps.deallocateInventoryError) {
      showErrorAlert(nextProps.deallocateInventoryError, () => {
        nextProps.clearMessages();
      });
    }
  }

  render() {
    const { inventory, deleteInventory, deAllocateInventory } = this.props;

    if (inventory) {
      const currentUserInfo = inventory.currentUserInfo;
      return (
        <div>
          <Card>
            <Col span={24} align="right">
              <Button
                size={size}
                style={styles.button}
                type="primary"
                icon="user-add"
                shape={"round"}
                onClick={() => {
                  if (currentUserInfo) {
                    showErrorMessage(Strings.allocateWarning);
                  } else {
                    this.props.toggleAllocateInventory(true);
                  }
                }}
              >
                {Strings.allocateDevice}
              </Button>
              <Link
                to={{
                  pathname: "/inventoryHistory/" + inventory.id
                }}
              >
                <Button
                  size={size}
                  style={styles.editButton}
                  type="primary"
                  icon="ordered-list"
                  shape={"round"}
                >
                  {Strings.inventoryHistory}
                </Button>
              </Link>

              <Link
                to={{
                  pathname: "/editInventory/" + inventory.id,
                  state: {
                    inventory: inventory
                  }
                }}
              >
                <Button
                  size={size}
                  style={styles.editButton}
                  type="primary"
                  icon="edit"
                  shape={"circle"}
                />
              </Link>

              <Button
                size={size}
                style={styles.editButton}
                type="primary"
                icon="delete"
                shape={"circle"}
                onClick={() => {
                  showDeleteWarning(() => {
                    deleteInventory(inventory);
                  });
                }}
              />
            </Col>
            <InventoryInfo inventory={inventory.inventoryInfo} />
          </Card>
          <PurchaseInfo inventory={inventory} />
          <CurrentUserInfo
            inventory={inventory}
            onDeallocateClick={deAllocateInventory}
          />
          <AllocateInventoryDialog
            {...this.props}
            users={this.props.users}
            onAllocateInventory={this.allocateInventory}
            isVisible={this.props.isAllocateInventoryVisible}
          />
        </div>
      );
    }
    return null;
  }
}

const mapStateToProps = state => {
  state = state.inventoryDetail;
  return {
    inventory: state.inventory,
    inventoryError: state.inventoryError,
    users: state.users,
    isAllocateInventoryVisible: state.isAllocateInventoryVisible,
    allocateInventorySuccess: state.allocateInventorySuccess,
    allocateInventoryError: state.allocateInventoryError,
    inventoryDeletedSuccess: state.inventoryDeletedSuccess,
    inventoryDeletedError: state.inventoryDeletedError,
    deallocateInventoryError: state.deallocateInventoryError,
    deallocateInventorySuccess: state.deallocateInventorySuccess
  };
};

export default connect(
  mapStateToProps,
  {
    fetchInventoryDetails,
    toggleAllocateInventory,
    fetchUsers,
    allocateInventory,
    clearMessages,
    deleteInventory,
    deAllocateInventory,
    removeListener
  }
)(InventoryDetails);

const styles = {
  formTitleText: {
    fontSize: 22,
    fontWeight: "500",
    color: Colors.gray
  },
  editButton: {
    marginLeft: 20
  }
};

InventoryDetails.propTypes = {
  inventory: PropTypes.object,
  inventoryError: PropTypes.any,
  users: PropTypes.array.isRequired,
  isAllocateInventoryVisible: PropTypes.bool,
  allocateInventorySuccess: PropTypes.string,
  allocateInventoryError: PropTypes.string,
  deallocateInventoryError: PropTypes.string,
  deallocateInventorySuccess: PropTypes.string
};
