import React from "react";
import { Row, Col, Card } from "antd";
import Strings from "../../utils/Strings";
const InventoryHistoryHeader = ({ isDevice }) => {
  return (
    <Card bodyStyle={styles.card}>
      <Row gutter={24}>
        <Col span={5}>
          <span style={styles.headerText}>
            {isDevice ? Strings.user : Strings.projectName}
          </span>
        </Col>
        <Col span={9}>
          <span style={styles.headerText}>{isDevice ? "" : Strings.users}</span>
        </Col>

        <Col span={5}>
          <span style={styles.headerText}>
            {isDevice ? Strings.claimedAt : Strings.allocatedAt}
          </span>
        </Col>
        <Col span={5}>
          <span style={styles.headerText}>
            {isDevice ? Strings.relinquishAt : Strings.deallocatedAt}
          </span>
        </Col>
      </Row>
    </Card>
  );
};
export default InventoryHistoryHeader;

const styles = {
  headerText: {
    fontSize: 16
  },
  card: {
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 20,
    paddingRight: 20
  }
};
