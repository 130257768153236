import React from "react";
import { Row, Col, Button, Modal } from "antd";
import "../../extendedStyles/listButton.css";
import Colors from "../../utils/Colors";
import { getDuration } from "../../utils/DateUtils";
import PropTypes from "prop-types";
import Strings from "../../utils/Strings";
import { Link } from "react-router-dom";
const confirm = Modal.confirm;

const DeviceCard = ({ device, onDelete, isAdmin }) => {
  const deviceHistoryLink = "/deviceHistory/" + device.id;

  return (
    <Row gutter={16}>
      <Col span={3}>
        <span style={styles.deviceId}>{device.deviceId}</span>
      </Col>
      <Col span={4}>
        <span style={styles.deviceName}>{device.deviceName}</span>
      </Col>
      <Col span={5}>
        <span style={styles.claimedByName}>{device.claimedByName}</span>
      </Col>
      <Col span={3}>
        <span style={styles.deviceClaimedAt}>{getDuration(device)}</span>
      </Col>
      <Col span={2}>
        <span style={styles.deviceOS}>{device.deviceOS}</span>
      </Col>
      <Col span={3} align="center">
        <span style={styles.deviceVersion}>{device.deviceVersion}</span>
      </Col>
      <Col span={4} align="right">
        {isAdmin ? (
          <Button
            shape="circle"
            icon="delete"
            onClick={() => handleDeleteClick(device, onDelete)}
          />
        ) : null}
        <Link to={deviceHistoryLink}>
          <Button style={styles.button} shape="round">
            {Strings.history}
          </Button>
        </Link>
      </Col>
    </Row>
  );
};

const handleDeleteClick = (device, onDelete) => {
  confirm({
    title: Strings.delete,
    content: Strings.deleteWarning,
    onOk() {
      onDelete(device);
    },
    onCancel() {}
  });
};

DeviceCard.propTypes = {
  device: PropTypes.object.isRequired,
  onDelete: PropTypes.func.isRequired
};

export default DeviceCard;

const styles = {
  deviceId: {
    fontSize: 16,
    fontWeight: 500
  },
  button: {
    marginLeft: 8
  },
  deviceName: {
    fontSize: 18,
    color: Colors.gray
  },
  deviceClaimedAt: {
    fontSize: 14,
    color: Colors.gray
  },
  claimedByName: {
    fontSize: 18,
    color: Colors.appColor
  },
  deviceVersion: {
    fontSize: 16,
    color: Colors.gray
  },
  deviceOS: {
    fontSize: 14,
    backgroundColor: Colors.lightGray,
    color: Colors.black,
    borderRadius: 16,
    paddingLeft: 16,
    paddingRight: 16,
    paddingTop: 8,
    paddingBottom: 8,
    width: 100
  }
};
