import {
  USERS_SUCCESS,
  USERS_FETCH,
  ADD_USER_VISIBLE,
  ADD_USER_ERROR,
  ADD_USER_SUCCESS,
  USER_DELETED_ERROR,
  USER_DELETED_SUCCESS,
  CLEAR_MESSAGES,
  EDIT_USER_VISIBLE
} from "../actions/ActionTypes";
const INITIAL_STATE = {
  users: [],
  storedUsers: [],
  isAddUserVisible: false,
  isEditUserVisible: false,
  addUserSuccess: "",
  addUserError: "",
  isLoading: true,
  user: {}
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case USERS_SUCCESS:
      return { ...state, users: action.users };
    case USERS_FETCH:
      return {
        ...state,
        users: action.users,
        storedUsers: action.users,
        isLoading: false
      };
    case ADD_USER_VISIBLE:
      return {
        ...state,
        isAddUserVisible: action.isVisible,
        addUserSuccess: "",
        addUserError: ""
      };
    case EDIT_USER_VISIBLE:
      return {
        ...state,
        isEditUserVisible: action.isVisible,
        user: action.user,
        addUserSuccess: "",
        addUserError: ""
      };
    case ADD_USER_SUCCESS:
      return {
        ...state,
        addUserSuccess: action.addUserSuccess,
        isAddUserVisible: false
      };
    case ADD_USER_ERROR:
      return { ...state, addUserError: action.addUserError };
    case USER_DELETED_SUCCESS:
      return { ...state, userDeletedSuccess: action.userDeletedSuccess };
    case USER_DELETED_ERROR:
      return { ...state, userDeletedError: action.userDeletedError };
    case CLEAR_MESSAGES:
      return {
        ...state,
        userDeletedError: undefined,
        userDeletedSuccess: undefined
      };
    default:
      return state;
  }
};
