import { combineReducers } from "redux";
import DevicesReducer from "./DevicesReducer";
import UsersReducer from "./UsersReducer";
import AuthReducer from "./AuthReducer";
import AddInventoryReducer from "./AddInventoryReducer";
import InventoryReducer from "./InventoryReducer";
import InventoryDetailsReducer from "./InventoryDetailsReducer";
import { LOGGED_OUT } from "../actions/ActionTypes";
const appReducer = combineReducers({
  devices: DevicesReducer,
  users: UsersReducer,
  auth: AuthReducer,
  addInventory: AddInventoryReducer,
  inventories: InventoryReducer,
  inventoryDetail: InventoryDetailsReducer
});

const rootReducer = (state, action) => {
  if (action.type === LOGGED_OUT) {
    state = undefined;
  }
  return appReducer(state, action);
};

export default rootReducer;
