import React from "react";
import { Col, Button, Form } from "antd";
import Strings from "../../utils/Strings";
import Colors from "../../utils/Colors";
import PropTypes from "prop-types";
import { Formik } from "formik";
import FormInput from "../../components/common/FormInput";
import { LoginValidationSchema } from "../../utils/ValidationSchemas";
const size = "large";

const LoginForm = props => {
  const toggleForgotPassword = props.toggleForgotPassword;
  return (
    <Formik
      validationSchema={LoginValidationSchema}
      initialValues={{
        email: "",
        name: ""
      }}
      onSubmit={(values, actions) => {
        props.logIn(values, actions);
      }}
      render={props => (
        <LoginFormInner
          toggleForgotPassword={toggleForgotPassword}
          {...props}
        />
      )}
    />
  );
};

const LoginFormInner = props => {
  const {
    values,
    touched,
    errors,
    handleBlur,
    isSubmitting,
    handleChange,
    isValid,
    handleSubmit,
    setFieldValue,
    toggleForgotPassword
  } = props;

  return (
    <Form onSubmit={handleSubmit}>
      <h1 style={styles.signInText}>{Strings.signIn}</h1>

      <div style={styles.inputTitle}>{Strings.email}</div>
      <FormInput
        id="email"
        value={values.email}
        placeholder={Strings.emailPlaceHolder}
        errorMessage={errors.email}
        type="email"
        handleBlur={handleBlur}
        onChange={e => {
          let text = e.target.value;
          const lastAlphabet = text[text.length - 1];
          if (!values.email.includes("@") && lastAlphabet === "@") {
            text = text + Strings.companyEmail; // if @ comes in text then concat official mail
          }
          setFieldValue("email", text);
        }}
      />
      <div style={styles.inputTitle}>{Strings.password}</div>

      <FormInput
        id="password"
        type="password"
        handleBlur={handleBlur}
        value={values.password}
        placeholder={Strings.passwordPlaceholder}
        errorMessage={touched.password && errors.password}
        onChange={handleChange}
      />
      <div
        onClick={() => {
          toggleForgotPassword(false);
        }}
        style={styles.forgotPassword}
      >
        {Strings.forgotPassword}
      </div>

      <Col align="right">
        <Button
          size={size}
          style={styles.button}
          type="primary"
          htmlType="submit"
          disabled={isSubmitting || !isValid}
          loading={isSubmitting}
        >
          {Strings.signIn}
        </Button>
      </Col>
    </Form>
  );
};

LoginFormInner.propTypes = {
  values: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired,
  isValid: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired
};

export default LoginForm;

const styles = {
  forgotPassword: {
    fontSize: 16,
    marginBottom: 20,
    marginTop: 10,
    color: Colors.appColor,
    borderBottomColor: "red",
    borderBottomWidth: 2
  },
  signInText: {
    fontSize: 26,
    fontWeight: "500",
    marginBottom: 20
  },
  inputTitle: {
    fontSize: 18,
    marginBottom: 10,
    color: Colors.gray
  },
  button: {
    marginBottom: 20
  }
};
