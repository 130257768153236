import React from "react";
import { Row, Col, Card } from "antd";
import Strings from "../../utils/Strings";
import Colors from "../../utils/Colors";
import Constants from "../../utils/Constants";
import PropTypes from "prop-types";
import FormInput from "../common/InventoryFormInput";
import FormSelect from "../common/FormSelect";
import FormAutocomplete from "../common/FormAutocomplete";
import FormSwitch from "../common/FormSwitch";
import TextAreaFormInput from "../common/TextAreaFormInput";
import "antd/dist/antd.css";

const AddInventoryInfoForm = props => {
  const { isEdit } = props;
  const values = props.values.inventoryInfo;
  let errors = props.errors.inventoryInfo;
  if (errors === undefined) {
    errors = {};
  }
  return (
    <Card>
      <h1 style={styles.formTitleText}>{Strings.inventoryInfo}</h1>
      <Row gutter={24}>
        <FormInput
          id="inventoryInfo.inventoryId"
          label={Strings.inventoryId}
          value={values.inventoryId}
          isRequired={true}
          placeholder={Strings.deviceIdPlaceholder}
          errorMessage={errors.inventoryId}
          {...props}
        />
        <Col span={8} style={styles.formInput}>
          <FormSelect
            isRequired={true}
            id="inventoryInfo.inventoryType"
            value={values.inventoryType}
            label={Strings.inventoryType}
            options={Constants.INVENTORY_TYPES}
            {...props}
          />
        </Col>
        <FormInput
          id="inventoryInfo.manufacturerName"
          label={Strings.companyName}
          value={values.manufacturerName}
          isRequired
          placeholder={Strings.manufacturerPlaceholder}
          errorMessage={errors.manufacturerName}
          {...props}
        />
        <FormAutocomplete
          id="inventoryInfo.inventoryOS"
          label={Strings.inventoryOS}
          value={values.inventoryOS}
          data={Constants.OS}
          isRequired
          placeholder={Strings.deviceOSPlaceholder}
          errorMessage={errors.inventoryOS}
          {...props}
        />
        <FormAutocomplete
          id="inventoryInfo.memorySize"
          label={Strings.memorySize}
          value={values.memorySize}
          data={Constants.MEMORY_SIZES}
          placeholder={Strings.deviceMemoryPlaceholder}
          {...props}
        />
        <FormInput
          id="inventoryInfo.modelNumber"
          label={Strings.modelNumber}
          value={values.modelNumber}
          {...props}
        />
        <FormInput
          id="inventoryInfo.serialNumber"
          label={Strings.serialNumber}
          value={values.serialNumber}
          {...props}
        />
        <FormInput
          id="inventoryInfo.imeiNumber"
          label={Strings.imeiNumber}
          value={values.imeiNumber}
          {...props}
        />
        <FormInput
          id="inventoryInfo.staticIpAddress"
          label={Strings.staticIpAddress}
          value={values.staticIpAddress}
          {...props}
        />
        <FormInput
          id="inventoryInfo.macAddress"
          label={Strings.macAddress}
          value={values.macAddress}
          {...props}
        />
        <FormInput
          id="inventoryInfo.lanMacAddress"
          label={Strings.lanMacAddress}
          value={values.lanMacAddress}
          {...props}
        />
        <TextAreaFormInput
          id="inventoryInfo.remark"
          label={Strings.remark}
          value={values.remark}
          {...props}
        />
        <FormSwitch
          id="inventoryInfo.isDeviceTrackerInstalled"
          label={Strings.deviceTrackerStatus}
          value={values.isDeviceTrackerInstalled}
          {...props}
        />
        {isEdit ? (
          <div>
            <FormSwitch
              id="inventoryInfo.isActive"
              inventoryStatus
              label={Strings.inventoryStatus}
              value={values.isActive}
              {...props}
            />
            <TextAreaFormInput
              id="inventoryInfo.issue"
              label={Strings.issue}
              value={values.issue}
              {...props}
            />
          </div>
        ) : null}
      </Row>
    </Card>
  );
};

AddInventoryInfoForm.propTypes = {
  values: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired,
  isValid: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired
};

export default AddInventoryInfoForm;

const styles = {
  formTitleText: {
    fontSize: 22,
    fontWeight: "500",
    color: Colors.gray
  }
};
