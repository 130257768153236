import React from "react";
import { Row, Col, Button, Icon } from "antd";
import "../../extendedStyles/listButton.css";
import Colors from "../../utils/Colors";
import PropTypes from "prop-types";
import { getDate } from "../../utils/DateUtils";
import { Link } from "react-router-dom";
import Strings from "../../utils/Strings";

const InventoryCard = ({ inventory }) => {
  const inventoryDetailLink = "/inventoryDetail/" + inventory.id;

  return (
    <Row gutter={8}>
      <Col span={3}>
        <div style={styles.inventoryId}>{inventory.inventoryId}</div>
        <div style={styles.macAddress}>{inventory.macAddress}</div>
      </Col>
      <Col span={4}>
        <div style={{ ...styles.projectName, ...styles.ellipsis }}>
          {inventory.projectName}
        </div>
      </Col>
      <Col span={2}>
        <span style={styles.inventoryType}>{inventory.inventoryType}</span>
      </Col>
      <Col span={3}>
        <div style={{ ...styles.inventoryOS, ...styles.ellipsis }}>
          {inventory.inventoryOS}
        </div>
      </Col>

      <Col span={3}>
        <div style={{ ...styles.manufacturerName, ...styles.ellipsis }}>
          {inventory.manufacturerName}
        </div>
      </Col>
      <Col span={3}>
        <div style={styles.purchaseDate}>{getDate(inventory.purchaseDate)}</div>
      </Col>
      <Col span={3}>
        <div style={{ ...styles.manufacturerName, ...styles.ellipsis }}>
          {inventory.purchaseType}
        </div>
      </Col>

      <Col span={3} align="right">
        {inventory.isActive ? (
          <Icon type={"check-circle"} style={styles.active} />
        ) : (
          <Icon type={"close-circle"} style={styles.inactive} />
        )}

        <Link to={inventoryDetailLink}>
          <Button style={styles.button} shape="round">
            {Strings.detail}
          </Button>
        </Link>
      </Col>
    </Row>
  );
};

InventoryCard.propTypes = {
  inventory: PropTypes.object.isRequired
};

export default InventoryCard;

const styles = {
  ellipsis: {
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "noWrap"
  },
  inactive: {
    fontSize: "22px",
    marginRight: 10,
    color: Colors.lightRed
  },
  active: {
    fontSize: "22px",
    marginRight: 10,
    color: Colors.lightGreen
  },
  inventoryId: {
    fontSize: 16,
    fontWeight: 500
  },
  button: {
    marginLeft: 3
  },
  macAddress: {
    fontSize: 14,
    marginTop: 5,
    color: Colors.gray
  },
  purchaseDate: {
    fontSize: 15,
    color: Colors.black
  },
  inventoryType: {
    fontSize: 16,
    color: Colors.appColor
  },
  manufacturerName: {
    fontSize: 16,
    color: Colors.gray
  },
  projectName: {
    fontSize: 16,
    color: Colors.gray
  },
  inventoryOS: {
    fontSize: 12,
    backgroundColor: Colors.lightGray,
    color: Colors.black,
    borderRadius: 16,
    paddingLeft: 16,
    paddingRight: 16,
    paddingTop: 8,
    paddingBottom: 8,
    width: 100
  }
};
