import FirebaseDBConstants from "../utils/FirebaseDBConstants";
import FirebaseDBManager from "../firebase/FirebaseDBManager";
import Constants from "../utils/Constants";
export const getDevices = callBack => {
  FirebaseDBManager.database
    .child(FirebaseDBConstants.DEVICES_DB)
    .on("value", function(snapshot) {
      var devices = [];
      if (snapshot.exists()) {
        snapshot.forEach(function(child) {
          devices.push(child.val());
        });
      }
      callBack(devices);
    });
};

export const requestToRemoveListener = () => {
  FirebaseDBManager.database.child(FirebaseDBConstants.DEVICES_DB).off("value");
};

export const requestToDeleteDevice = device => {
  var updates = {};
  updates[FirebaseDBConstants.DEVICES_DB + "/" + device.id] = null;
  updates[FirebaseDBConstants.DEVICES_HISTORY_DB + "/" + device.id] = null;
  return new Promise((resolve, reject) => {
    FirebaseDBManager.database.update(updates, function(error) {
      if (error) {
        reject(error);
      } else {
        resolve();
      }
    });
  });
};

export const getSearchDevices = (text, devices) => {
  const searchText = text.toLowerCase();
  try {
    const searchDevices = devices.filter(device => {
      return (
        device.deviceName.toLowerCase().includes(searchText) ||
        device.deviceId.toLowerCase().includes(searchText) ||
        device.deviceOS.toLowerCase().includes(searchText) ||
        (device.claimedByName !== undefined &&
          device.claimedByName.toLowerCase().includes(searchText))
      );
    });
    return searchDevices;
  } catch (error) {
    console.log(error);
    return devices;
  }
};

export const getFilterDevices = (filterType, devices) => {
  try {
    return devices.filter(device => {
      if (
        filterType === Constants.FILTER_CLAIMED_DEVICES &&
        device.claimedBy !== undefined
      ) {
        return true;
      } else if (
        filterType === Constants.FILTER_AVAILABLE_DEVICES &&
        device.claimedBy === undefined
      ) {
        return true;
      } else if (filterType === Constants.FILTER_CLEAR) {
        return true;
      }
      return false;
    });
  } catch (error) {
    console.log(error);
    return devices;
  }
};

export const getDeviceHistory = deviceId => {
  return new Promise((resolve, reject) => {
    FirebaseDBManager.database
      .child(FirebaseDBConstants.DEVICES_HISTORY_DB)
      .child(deviceId)
      .once("value")
      .then(function(snapshot) {
        const deviceHistory = [];
        if (snapshot.exists()) {
          snapshot.forEach(function(child) {
            var history = child.val();
            deviceHistory.push(history);
          });
          resolve(deviceHistory);
        } else {
          resolve(deviceHistory);
        }
      })
      .catch(error => {
        reject(error);
      });
  });
};
