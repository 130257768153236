export const LOADING = "loading";
export const DEVICES_SUCCESS = "devices_success";
export const ON_SEARCH_DEVICE = "on_search_device";
export const DEVICES_FAILURE = "devices_failure";
export const DEVICES_EMPTY = "devices_empty";
export const FILTER_VISIBLE = "filter_visible";
export const INVENTORY_FILTER_VISIBLE = "inventory_filter_visible";
export const FILTER_TYPE = "filter_type";
export const DEVICES_FETCH = "filter_fetch";
export const DEVICES_FILTERED = "filter_filtered";
export const DEVICE_TRACKER_HISTORY_LOADING = "deviceTrackerHistoryLoading";
export const DEVICE_TRACKER_HISTORY_SUCCESS = "deviceTrackerHistorySuccess";
export const DEVICE_TRACKER_HISTORY_ERROR = "deviceTrackerHistoryError";
export const DEVICE_DELETED_SUCCESS = "device_deleted_success";
export const DEVICE_DELETED_ERROR = "device_deleted_error";
export const CLEAR_MESSAGES = "clear_messages";
export const CHANGE_PAGE_NUMBER = "change_page_number";
export const CHANGE_INVENTORY_PAGE_NUMBER = "change_inventory_page_number";
export const USERS_SUCCESS = "users_success";
export const USERS_FETCH = "users_fetch";
export const ADD_USER_VISIBLE = "add_user_visible";
export const ADD_USER_SUCCESS = "add_user_success";
export const ADD_USER_ERROR = "add_user_error";
export const USER_DELETED_SUCCESS = "user_deleted_success";
export const USER_DELETED_ERROR = "user_deleted_error";
export const EDIT_USER_VISIBLE = "edit_user_visible";
export const LOGIN_SUCCESS = "login_success";
export const LOGIN_ERROR = "login_error";
export const LOGGED_IN = "logged_in";
export const LOGGED_OUT = "logged_out";
export const FORGOT_PASSWORD_VISBLE = "forgot_password_visible";
export const FORGOT_PASSWORD_SUCCESS = "forgot_password_success";
export const ADD_INVENTORY_SUCCESS = "add_inventory_success";
export const ADD_INVENTORY_ERROR = "add_inventory_error";
export const EDIT_ENVENTORY = "edit_inventory";
export const INVENTORY_HISTORY__SUCCESS = "inventory_history_success";
export const INVENTORY_HISTORY_ERROR = "inventory_history_error";
export const CLEAR_HISTORY = "clear_history";
export const INVENTORY_FETCH_SUCCESS = "inventory_fetch_success";
export const INVENTORIES_FETCH_SUCCESS = "inventories_fetch_success";
export const INVENTORY_FETCH_ERROR = "inventory_fetch_error";
export const ALLOCATE_INVENTORY_VISIBLE = "allocate_inventory_visible";
export const ALLOCATE_INVENTORY_SUCCESS = "allocate_inventory_success";
export const ALLOCATE_INVENTORY_ERROR = "allocate_inventory_error";
export const INVENTORY_DELETED_SUCCESS = "inventory_deleted_success";
export const INVENTORY_DELETED_ERROR = "inventory_deleted_error";
export const DE_ALLOCATE_INVENTORY_SUCCESS = "deallocate_inventory_success";
export const DE_ALLOCATE_INVENTORY_ERROR = "deallocate_inventory_success";
export const INVENTORIES_FILTERED = "inventories_filtered";
export const IS_HISTORY_SELECTED = "is_history_selected";
