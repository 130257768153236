import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "../src/containers/MainDashboard";
import * as serviceWorker from "./serviceWorker";
import ReduxThunk from "redux-thunk";
import { Provider } from "react-redux";
import { createStore, applyMiddleware } from "redux";
import reducers from "./reducers";
import { HashRouter } from "react-router-dom";
import { CookiesProvider } from "react-cookie";

ReactDOM.render(
  <CookiesProvider>
    <HashRouter hashType="noslash">
      <Provider store={createStore(reducers, {}, applyMiddleware(ReduxThunk))}>
        <App />
      </Provider>
    </HashRouter>
  </CookiesProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
