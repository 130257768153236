import {
  requestToAddInventory,
  requestToUpdateInventory,
  checkInventoryAlreadyExist
} from "../services/AddInventoryService";
import {
  ADD_INVENTORY_SUCCESS,
  ADD_INVENTORY_ERROR,
  CLEAR_MESSAGES,
  EDIT_ENVENTORY
} from "./ActionTypes";
import Strings from "../utils/Strings";
import { handleError } from "../utils/HandleError";
export const addInventory = (inventory, actions) => async dispatch => {
  try {
    await checkInventoryAlreadyExist(inventory);
    await requestToAddInventory(inventory);
    resetActions(actions);
    dispatch({
      type: ADD_INVENTORY_SUCCESS,
      addSuccess: Strings.addInventorySuccess
    });
  } catch (error) {
    actions.setSubmitting(false);
    actions.setTouched({});
    dispatch({ type: ADD_INVENTORY_ERROR, addError: handleError(error) });
  }
};

const resetActions = actions => {
  actions.setSubmitting(false);
  actions.setValues({
    inventoryInfo: {},
    purchaseInfo: {
      isClientDevice: false,
      isInsured: false
    }
  });
};

export const updateInventory = (inventory, actions) => async dispatch => {
  try {
    await requestToUpdateInventory(inventory);
    resetActions(actions);
    dispatch({
      type: ADD_INVENTORY_SUCCESS,
      addSuccess: Strings.updateInventorySuccess
    });
  } catch (error) {
    console.log(error);

    actions.setSubmitting(false);
    actions.setTouched({});
    dispatch({ type: ADD_INVENTORY_ERROR, addError: handleError(error) });
  }
};

export const clearMessages = () => dispatch => {
  dispatch({ type: CLEAR_MESSAGES });
};

export const setInitialInventory = inventory => dispatch => {
  dispatch({ type: EDIT_ENVENTORY, initialInventory: inventory });
};
