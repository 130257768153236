import React from "react";
import { Row, Card, Col, Button } from "antd";
import Strings from "../../utils/Strings";
import Colors from "../../utils/Colors";
import PropTypes from "prop-types";
import FormInput from "../common/InventoryFormInput";
import FormDatePicker from "../common/InventoryFormDatePicker";
import "antd/dist/antd.css";
import FormSwitch from "../common/FormSwitch";
import Constants from "../../utils/Constants";
import FormSelect from "../common/FormSelect";
import Moment from "moment";

const disabledDate = current => {
  return current && current > Moment().endOf("day");
};

const AddInventoryPurchaseInfoForm = props => {
  const { isSubmitting, isValid, isEdit } = props;
  const values = props.values.purchaseInfo;
  let errors = props.errors.purchaseInfo;
  if (errors === undefined) {
    errors = {};
  }
  const disabledReturnDate = current => {
    if (values.purchaseDate) {
      return current && current < values.purchaseDate;
    } else {
      return current < Moment().startOf("day");
    }
  };

  return (
    <Card style={styles.mainContainer}>
      <h1 style={styles.formTitleText}>{Strings.purchaseInfo}</h1>
      <Row gutter={24}>
        <FormDatePicker
          id="purchaseInfo.purchaseDate"
          label={Strings.purchaseDate}
          value={values.purchaseDate}
          disabledDate={disabledDate}
          isRequired
          {...props}
        />
        <FormInput
          id="purchaseInfo.vendorName"
          label={Strings.vendorName}
          value={values.vendorName}
          placeholder={Strings.vendorNamePlaceholder}
          {...props}
        />

        <Col span={5}>
          <FormSelect
            isRequired={true}
            id="purchaseInfo.purchaseType"
            value={values.purchaseType}
            label={Strings.purchaseType}
            options={Constants.PURCHASE_TYPES}
            {...props}
          />
        </Col>

        <FormSwitch
          span={3}
          id="purchaseInfo.isInsured"
          label={Strings.insuranceStatus}
          value={values.isInsured}
          {...props}
        />
        {values.purchaseType === Constants.PURCHASE_TYPE_ON_RENT && isEdit ? (
          <Col span={24} style={styles.returnDateCol}>
            <FormDatePicker
              id="purchaseInfo.returnDate"
              label={Strings.returnDate}
              value={values.returnDate}
              disabledDate={disabledReturnDate}
              {...props}
            />
          </Col>
        ) : null}
        <Col span={24} align="right">
          <Button
            size={"large"}
            style={styles.button}
            type="primary"
            loading={isSubmitting}
            disabled={isSubmitting || !isValid}
            htmlType="submit"
          >
            {Strings.save}
          </Button>
        </Col>
      </Row>
    </Card>
  );
};

AddInventoryPurchaseInfoForm.propTypes = {
  values: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired,
  isValid: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired
};

export default AddInventoryPurchaseInfoForm;

const styles = {
  mainContainer: {
    marginTop: 20
  },
  returnDateCol: {
    padding: 0
  },
  formTitleText: {
    fontSize: 22,
    fontWeight: "500",
    color: Colors.gray
  },
  button: {
    width: "10%",
    marginTop: 30,
    marginBottom: 10
  },
  inputTitle: {
    fontSize: 18,
    marginBottom: 10,
    color: Colors.gray
  },
  formInput: {
    paddingRight: 20
  }
};
