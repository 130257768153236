import React from "react";
import { Row, Col, Card } from "antd";
import Strings from "../../utils/Strings";
const DeviceHeader = () => {
  return (
    <Card bodyStyle={styles.card}>
      <Row gutter={16}>
        <Col span={7}>
          <span style={styles.headerText}>{Strings.name}</span>
        </Col>
        <Col span={10}>
          <span style={styles.headerText}>{Strings.email}</span>
        </Col>
      </Row>
    </Card>
  );
};
export default DeviceHeader;

const styles = {
  headerText: {
    fontSize: 16
  },
  card: {
    padding: 10
  }
};
