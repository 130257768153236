import {
  requestToAllocateInventory,
  getInventoryDetails,
  requestToDeleteInventory,
  getInventoryHistory,
  requestToDeallocateInventory,
  requestToRemoveInventoryInfoListener,
  requestToAddInventoryHistory,
  requestToRemoveInventoryHistoryListener
} from "../services/InventoryListService";
import { getUsers } from "../services/UserListService";
import {
  INVENTORY_HISTORY__SUCCESS,
  INVENTORY_FETCH_SUCCESS,
  INVENTORY_FETCH_ERROR,
  ALLOCATE_INVENTORY_VISIBLE,
  USERS_FETCH,
  ALLOCATE_INVENTORY_SUCCESS,
  ALLOCATE_INVENTORY_ERROR,
  CLEAR_MESSAGES,
  INVENTORY_DELETED_SUCCESS,
  INVENTORY_DELETED_ERROR,
  DE_ALLOCATE_INVENTORY_SUCCESS,
  DE_ALLOCATE_INVENTORY_ERROR
} from "./ActionTypes";
import Strings from "../utils/Strings";
import { handleError } from "../utils/HandleError";

export const toggleAllocateInventory = isVisible => dispatch => {
  return dispatch({ type: ALLOCATE_INVENTORY_VISIBLE, payload: isVisible });
};

export const fetchUsers = () => dispatch => {
  return getUsers(users => {
    dispatch({ type: USERS_FETCH, users: users });
  });
};

export const clearMessages = () => dispatch => {
  dispatch({
    type: CLEAR_MESSAGES
  });
};

export const deleteInventory = inventory => async dispatch => {
  try {
    requestToRemoveInventoryInfoListener(inventory.id);
    await requestToDeleteInventory(inventory);
    dispatch({
      type: INVENTORY_DELETED_SUCCESS,
      inventoryDeletedSuccess: Strings.inventoryDeletedSuccess
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: INVENTORY_DELETED_ERROR,
      inventoryDeletedError: handleError(error)
    });
  }
};

export const fetchInventoryDetails = inventoryId => async dispatch => {
  getInventoryDetails(
    inventoryId,
    inventory => {
      dispatch({
        type: INVENTORY_FETCH_SUCCESS,
        inventory: inventory
      });
    },
    error => {
      dispatch({ type: INVENTORY_FETCH_ERROR, inventoryError: error });
    }
  );
};

export const removeListener = inventoryId => {
  requestToRemoveInventoryInfoListener(inventoryId);
};

export const removeHistoryListener = inventoryId => {
  requestToRemoveInventoryHistoryListener(inventoryId);
};

export const fetchInventoryHistory = inventoryId => async dispatch => {
  getInventoryHistory(inventoryId, history => {
    dispatch({
      type: INVENTORY_HISTORY__SUCCESS,
      inventoryHistory: history.reverse()
    });
  });
};

export const allocateInventory = (
  allocationDetails,
  inventoryId,
  actions
) => async dispatch => {
  clearMessages();
  try {
    await requestToAllocateInventory(allocationDetails, inventoryId);
    actions.setValues({ users: undefined });
    actions.setSubmitting(false);
    dispatch({
      type: ALLOCATE_INVENTORY_SUCCESS,
      allocateInventorySuccess: Strings.allocateInventorySuccess
    });
  } catch (error) {
    actions.setSubmitting(false);
    console.log(error);
    dispatch({
      type: ALLOCATE_INVENTORY_ERROR,
      allocateInventoryError: handleError(error)
    });
  }
};

export const addInventoryHistory = (
  allocationDetails,
  inventoryId,
  actions
) => async dispatch => {
  clearMessages();
  console.log(allocationDetails);

  try {
    await requestToAddInventoryHistory(allocationDetails, inventoryId);
    actions.setValues({ users: undefined });
    actions.setSubmitting(false);
    dispatch({
      type: ALLOCATE_INVENTORY_SUCCESS,
      allocateInventorySuccess: Strings.allocateInventorySuccess
    });
  } catch (error) {
    actions.setSubmitting(false);
    console.log(error);
    dispatch({
      type: ALLOCATE_INVENTORY_ERROR,
      allocateInventoryError: handleError(error)
    });
  }
};

export const deAllocateInventory = (
  allocationDetails,
  inventoryId
) => async dispatch => {
  clearMessages();
  try {
    await requestToDeallocateInventory(allocationDetails, inventoryId);
    dispatch({
      type: DE_ALLOCATE_INVENTORY_SUCCESS,
      deallocateInventorySuccess: Strings.deallocateInventorySuccess
    });
  } catch (error) {
    dispatch({
      type: DE_ALLOCATE_INVENTORY_ERROR,
      deallocateInventoryError: handleError(error)
    });
  }
};
