import React, { Component } from "react";
import FirebaseDBManager from "../firebase/FirebaseDBManager";
import "antd/dist/antd.css";
import { Form } from "antd";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import "../extendedStyles/button.css";
import { Formik } from "formik";
import AddInventoryInfoForm from "../components/forms/AddInventoryInfoForm";
import AddInventoryPurchaseInfoForm from "../components/forms/AddInventoryPurchaseInfoForm";
import {
  updateInventory,
  clearMessages,
  setInitialInventory
} from "../actions/AddInventoryActions";
import { AddInventorySchema } from "../utils/ValidationSchemas";
import { showSuccessAlert, showErrorMessage } from "../utils/ShowAlertDialog";
class AddInventory extends Component {
  componentDidMount() {
    FirebaseDBManager.init();
    const {
      match: { params },
      location
    } = this.props;
    if (params.inventoryId) {
      try {
        const { inventory } = location.state;     
        this.props.setInitialInventory(inventory);
      } catch (e) {
        this.props.history.goBack();
      }
    } else {
      this.props.history.goBack();
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.addSuccess) {
      showSuccessAlert(nextProps.addSuccess, () => {
        nextProps.clearMessages();
        this.props.history.goBack();
      });
    }
    if (nextProps.addError) {
      showErrorMessage(nextProps.addError);
      nextProps.clearMessages();
    }
  }

  render() {    
    return (
      <Formik
        validationSchema={AddInventorySchema}
        onSubmit={(values, actions) => {
          this.props.updateInventory(values, actions);
        }}
        enableReinitialize={true}
        initialValues={this.props.initialInventory}
        render={props => {
          props.isEdit = true;
          return (
            <Form onSubmit={props.handleSubmit}>
              <AddInventoryInfoForm {...props} />
              <AddInventoryPurchaseInfoForm {...props} />
            </Form>
          );
        }}
      />
    );
  }
}

const mapStateToProps = state => {
  state = state.addInventory;
  return {
    initialInventory: state.initialInventory,
    addSuccess: state.addSuccess,
    addError: state.addError
  };
};

AddInventory.propTypes = {
  initialInventory: PropTypes.object.isRequired,
  addSuccess: PropTypes.string,
  addError: PropTypes.string
};

export default connect(
  mapStateToProps,
  {
    updateInventory,
    clearMessages,
    setInitialInventory
  }
)(AddInventory);
