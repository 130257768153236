import React from "react";
import { Col, Button, Form, Icon, Row } from "antd";
import Strings from "../../utils/Strings";
import Colors from "../../utils/Colors";
import PropTypes from "prop-types";
import { Formik } from "formik";
import FormInput from "../common/FormInput";
import { ForgotPasswordValidationSchema } from "../../utils/ValidationSchemas";
const size = "large";

const ForgotPasswordForm = props => {
  const toggleForgotPassword = props.toggleForgotPassword;
  return (
    <Formik
      validationSchema={ForgotPasswordValidationSchema}
      initialValues={{
        email: "",
        name: ""
      }}
      onSubmit={(values, actions) => {
        props.forgotPassword(values, actions);
      }}
      render={props => (
        <ForgotPasswordFormInner
          toggleForgotPassword={toggleForgotPassword}
          {...props}
        />
      )}
    />
  );
};

const ForgotPasswordFormInner = props => {
  const {
    values,
    errors,
    handleBlur,
    isSubmitting,
    isValid,
    handleSubmit,
    setFieldValue,
    toggleForgotPassword
  } = props;

  return (
    <Form onSubmit={handleSubmit}>
      <Row>
        <Icon
          onClick={() => toggleForgotPassword(true)}
          type="left"
          style={styles.leftBtn}
        />
        <span style={styles.signInText}>{Strings.forgotPassword}</span>
      </Row>

      <div style={styles.inputTitle}>{Strings.email}</div>
      <FormInput
        id="email"
        value={values.email}
        placeholder={Strings.emailPlaceHolder}
        errorMessage={errors.email}
        type="email"
        handleBlur={handleBlur}
        onChange={e => {
          let text = e.target.value;
          const lastAlphabet = text[text.length - 1];
          if (!values.email.includes("@") && lastAlphabet === "@") {
            text = text + Strings.companyEmail; // if @ comes in text then concat official mail
          }
          setFieldValue("email", text);
        }}
      />

      <Col align="right">
        <Button
          size={size}
          style={styles.button}
          type="primary"
          htmlType="submit"
          disabled={isSubmitting || !isValid}
          loading={isSubmitting}
        >
          {Strings.forgotPasswordTitle}
        </Button>
      </Col>
    </Form>
  );
};

ForgotPasswordFormInner.propTypes = {
  values: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  isValid: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired
};

export default ForgotPasswordForm;

const styles = {
  logoStyle: {
    height: 45,
    marginLeft: 20
  },
  leftBtn: {
    fontSize: 20,
    color: Colors.black,
    marginRight: 20
  },

  inputTitle: {
    fontSize: 18,
    marginTop: 20,
    marginBottom: 10,
    color: Colors.gray
  },
  signInText: {
    width: 300,
    fontSize: 26,
    fontWeight: "500"
  },
  button: {
    marginTop: 150,
    marginBottom: 20
  }
};
