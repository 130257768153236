import React, { Component } from "react";
import FirebaseDBManager from "../firebase/FirebaseDBManager";
import { List } from "antd";
import "antd/dist/antd.css";
import InventoryhistoryCard from "../components/listItems/InventoryHistoryCard";
import InventoryHistoryHeader from "../components/listItems/InventoryHistoryHeader";
import { fetchDeviceHistory } from "../actions/DeviceActions";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import "../extendedStyles/button.css";
import { showErrorAlert } from "../utils/ShowAlertDialog";
const { Item } = List;
class DeviceTrackerHistory extends Component {
  
  componentDidMount() {
    FirebaseDBManager.init();
    const {
      match: { params }
    } = this.props;
    if (params.deviceId) {
      this.props.fetchDeviceHistory(params.deviceId);
    } else {
      this.props.history.goBack();
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.deviceHistoryError) {
      showErrorAlert(nextProps.deviceHistoryError, () => {
        nextProps.clearMessages();
        this.props.history.goBack();
      });
    }
  }

  render() {
    return (
      <div>
        <List
          header={<InventoryHistoryHeader isDevice={true} />}
          itemLayout="vertical"
          bordered={false}
          loading={this.props.isHistoryLoading}
          pagination={true}
          dataSource={this.props.deviceHistory}
          renderItem={item => (
            <Item style={styles.deviceItem}>
              <InventoryhistoryCard inventoryHistory={item} isDevice={true} />
            </Item>
          )}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  state = state.devices;
  return {
    deviceHistory: state.deviceHistory,
    isHistoryLoading:state.isHistoryLoading,
    deviceHistoryError:state.deviceHistoryError
  };
};

export default connect(
  mapStateToProps,
  {
    fetchDeviceHistory
  }
)(DeviceTrackerHistory);

const styles = {
  deviceItem: {
    backgroundColor: "white",
    padding: 20
  }
};

DeviceTrackerHistory.propTypes = {
  deviceHistory: PropTypes.array.isRequired,
  deviceHistoryError: PropTypes.string
};
