import React, { Component } from "react";
import FirebaseDBManager from "../firebase/FirebaseDBManager";
import { List, Input, Row, Col, Button } from "antd";
import "antd/dist/antd.css";
import InventoryCard from "../components/listItems/InventoryCard";
import InventoryHeader from "../components/listItems/InventoryHeader";
import {
  fetchInventories,
  toggleFilter,
  filterInventories,
  searchInventories,
  clearMessages,
  removeListener,
  changePageNumber
} from "../actions/InventoryActions";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Strings from "../utils/Strings";
import InventoryFilterDialog from "../components/dialogs/InventoryFilterDialog";
import "../extendedStyles/button.css";
import DownloadCSV from "../components/common/DownloadCSV";
const Search = Input.Search;
const { Item } = List;
const size = "large";
class InventoryList extends Component {
  constructor() {
    super();
    this.toggleFilter = this.toggleFilter.bind(this);
  }
  componentDidMount() {
    FirebaseDBManager.init();
    this.getInventories();
  }

  componentWillUnmount() {
    removeListener();
  }

  getInventories = () => {
    this.props.fetchInventories();
  };

  toggleFilter(isVisible) {
    this.props.toggleFilter(isVisible);
  }

  changeFilterType(filterType) {
    this.props.changeFilterType(filterType);
  }

  onChange = e => {
    this.props.searchInventories(e.target.value);
  };

  render() {
    return (
      <div>
        <Row>
          <Col span={12}>
            <Search
              placeholder={Strings.searchHere}
              size={size}
              allowClear={true}
              onChange={this.onChange}
              onSearch={text => this.props.searchInventories(text)}
              enterButton
            />
          </Col>

          <Col span={12} align="right">
            <DownloadCSV inventories={this.props.inventories} />
            <Button
              type="primary"
              shape="round"
              icon="filter"
              size={size}
              onClick={() => this.toggleFilter(true)}
            >
              {Strings.filter}
            </Button>
          </Col>
        </Row>

        <List
          header={<InventoryHeader />}
          itemLayout="vertical"
          bordered={false}
          pagination={{
            showSizeChanger: false,
            defaultCurrent: 1,
            current: this.props.pageNumber,
            showTotal: total => ` Total: ${total}`,
            onChange: page => {
              this.props.changePageNumber(page);
            }
          }}
          loading={this.props.isLoading}
          dataSource={this.props.inventories}
          renderItem={item => (
            <Item style={styles.deviceItem}>
              <InventoryCard
                inventory={item}
                onDelete={this.props.deleteInventory}
              />
            </Item>
          )}
        />
        <InventoryFilterDialog
          toggleFilter={this.toggleFilter}
          selectedFilter={this.props.filterType}
          filterInventories={this.props.filterInventories}
          isVisible={this.props.isFilterVisible}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  state = state.inventories;
  return {
    inventories: state.inventories,
    isFilterVisible: state.isFilterVisible,
    filterType: state.filterType,
    isLoading: state.isLoading,
    pageNumber: state.pageNumber
  };
};

export default connect(
  mapStateToProps,
  {
    fetchInventories,
    toggleFilter,
    filterInventories,
    searchInventories,
    clearMessages,
    changePageNumber
  }
)(InventoryList);

const styles = {
  deviceItem: {
    backgroundColor: "white",
    padding: 20
  },
  search: {
    width: "50%",
    borderRadius: 30
  },
  filterBtnView: {
    width: "100%",
    justifyContent: "flex-end"
  }
};

InventoryList.propTypes = {
  inventories: PropTypes.array,
  isFilterVisible: PropTypes.bool.isRequired,
  filterType: PropTypes.number.isRequired,
  deviceDeletedSuccess: PropTypes.string,
  deviceDeletedError: PropTypes.string,
  isLoading: PropTypes.bool.isRequired,
  pageNumber: PropTypes.number.isRequired
};
