import React, { Component } from "react";
import FirebaseDBManager from "../firebase/FirebaseDBManager";
import { List, Input, Row, Col, Button } from "antd";
import "antd/dist/antd.css";
import UserCard from "../components/listItems/UserCard";
import UserHeader from "../components/listItems/UserHeader";
import { showSuccessAlert, showErrorAlert } from "../utils/ShowAlertDialog";
import {
  fetchUsers,
  searchUsers,
  toggleAddUser,
  addUser,
  deleteUser,
  clearMessages,
  removeListener,
  toggleEditUser,
  updateUser
} from "../actions/UsersActions";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Strings from "../utils/Strings";
import "../extendedStyles/button.css";
import AddUserDialog from "../components/dialogs/AddUserDialog";
import EditUserDialog from "../components/dialogs/EditUserDialog";
const Search = Input.Search;
const { Item } = List;
const size = "large";
class UsersList extends Component {
  constructor() {
    super();
    this.toggleAddUser = this.toggleAddUser.bind(this);
  }

  componentDidMount() {
    FirebaseDBManager.init();
    this.getDevices();
  }
  componentWillUnmount() {
    removeListener();
  }

  getDevices = () => {
    this.props.fetchUsers();
  };

  onChange = e => {
    this.props.searchUsers(e.target.value);
  };

  toggleAddUser(isVisible) {
    this.props.toggleAddUser(isVisible);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.userDeletedSuccess) {
      showSuccessAlert(nextProps.userDeletedSuccess, () => {
        nextProps.clearMessages();
      });
    }

    if (nextProps.userDeletedError) {
      showErrorAlert(nextProps.userDeletedError, () => {
        nextProps.clearMessages();
      });
    }
  }

  render() {
    return (
      <div>
        <Row>
          <Col span={12}>
            <Search
              placeholder={Strings.searchHere}
              size={size}
              allowClear={true}
              onChange={this.onChange}
              onSearch={text => this.props.searchUsers(text)}
              enterButton
            />
          </Col>

          <Col span={12} align="right">
            <Button
              type="primary"
              shape="round"
              icon="user"
              size={size}
              onClick={() => this.toggleAddUser(true)}
            >
              {Strings.addUser}
            </Button>
          </Col>
        </Row>

        <List
          header={<UserHeader />}
          itemLayout="vertical"
          loading={this.props.isLoading}
          bordered={false}
          pagination={{
            showSizeChanger: true,
            showTotal: total => ` Total: ${total}`
          }}
          dataSource={this.props.users}
          renderItem={item => (
            <Item style={styles.deviceItem}>
              <UserCard
                user={item}
                onDelete={this.props.deleteUser}
                toggleEditUser={this.props.toggleEditUser}
              />
            </Item>
          )}
        />

        <AddUserDialog
          {...this.props}
          isVisible={this.props.isAddUserVisible}
        />

        <EditUserDialog
          {...this.props}
          isVisible={this.props.isEditUserVisible}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  state = state.users;
  return {
    users: state.users,
    isAddUserVisible: state.isAddUserVisible,
    addUserSuccess: state.addUserSuccess,
    addUserError: state.addUserError,
    userDeletedSuccess: state.userDeletedSuccess,
    userDeletedError: state.userDeletedError,
    isLoading: state.isLoading,
    isEditUserVisible: state.isEditUserVisible,
    user: state.user
  };
};

export default connect(
  mapStateToProps,
  {
    fetchUsers,
    searchUsers,
    toggleAddUser,
    addUser,
    deleteUser,
    clearMessages,
    toggleEditUser,
    updateUser
  }
)(UsersList);

const styles = {
  deviceItem: {
    backgroundColor: "white",
    padding: 20
  },
  search: {
    width: "50%",
    borderRadius: 30
  },
  filterBtnView: {
    width: "100%",
    justifyContent: "flex-end"
  }
};

UsersList.propTypes = {
  users: PropTypes.array.isRequired,
  isAddUserVisible: PropTypes.bool.isRequired,
  toggleAddUser: PropTypes.func.isRequired,
  addUser: PropTypes.func.isRequired,
  addUserSuccess: PropTypes.string.isRequired,
  addUserError: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired
};
