import React from "react";
import { Row, Col, Card } from "antd";
import Strings from "../../utils/Strings";
const DeviceHeader = () => {
  return (
    <Card bodyStyle={styles.card}>
      <Row gutter={24}>
        <Col span={3}>
          <span style={styles.headerText}>{Strings.deviceId}</span>
        </Col>
        <Col span={4}>
          <span style={styles.headerText}>{Strings.deviceName}</span>
        </Col>
        <Col span={5}>
          <span style={styles.headerText}>{Strings.claimedBy}</span>
        </Col>
        <Col span={3}>
          <span style={styles.headerText}>{Strings.claimedAt}</span>
        </Col>
        <Col span={2}>
          <span style={styles.headerText}>{Strings.deviceOs}</span>
        </Col>
        <Col span={3} align="center">
          <span style={styles.headerText}>{Strings.osVersion}</span>
        </Col>
        <Col span={4} align="right  " />
      </Row>
    </Card>
  );
};
export default DeviceHeader;

const styles = {
  headerText: {
    fontSize: 16
  },
  card: {
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 20,
    paddingRight: 20
  }
};
