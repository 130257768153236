const FirebaseConstants = {
  USERS_DB: "users",
  INVENTORIES_DB: "inventories",
  INVENTORIES_HISTORY: "inventoriesHistory",
  INVENTORIES_INFO_DB: "inventoriesInfo",
  DEVICES_HISTORY_DB:'deviceTrackerHistory',
  DEVICES_DB: "devices",
  USER_EMAIL: "userEmail",
  DEVICE_ID: "deviceId",
  LAN_MAC_ADDRESS: "lanMacAddress",
  MAC_ADDRESS: "macAddress",
  CLAIMED_BY: "claimedBy",
  CLAIMED_BY_NAME: "claimedByName",
  CLAIMED_AT: "claimedAt",
  USER_NAME: "userName",
  IS_ADMIN: "isAdmin"
};
export default FirebaseConstants;
