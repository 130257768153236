import React from "react";
import { Input, Form } from "antd";
import PropTypes from "prop-types";
const { Item } = Form;
const size = "large";
const FormInput = props => {
  return (
    <Item
      label={props.label}
      required={props.isRequired}
      validateStatus={props.errorMessage ? "error" : ""}
      help={props.errorMessage}
    >
      <Input
        style={styles.input}
        label={props.id}
        name={props.id}
        onBlur={props.handleBlur}
        type={props.type}
        placeholder={props.placeholder}
        size={size}
        value={props.value}
        onChange={props.onChange}
      />
    </Item>
  );
};

const styles = {
  input: {
    marginBottom: 5
  },
  button: {
    width: "100%",
    marginTop: 10
  }
};

FormInput.propTypes = {
  id: PropTypes.string.isRequired,
  value: PropTypes.any,
  onChange: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.any
};

export default FormInput;
