import { Row, Card, Col, Button } from "antd";
import React from "react";
import InventoryItem from "../common/InventoryInfoItem";
import UsersTag from "../common/UsersTag";
import Colors from "../../utils/Colors";
import Strings from "../../utils/Strings";
import { getDateAndTime } from "../../utils/DateUtils";
import "../../extendedStyles/antTag.css";
const CurrentUserInfo = ({ inventory, onDeallocateClick }) => {
  const currentUserInfo = inventory.currentUserInfo;
  if (currentUserInfo) {
    return (
      <Card style={styles.card}>
        <Row>
          <Col span={12}>
            <h1 style={styles.formTitleText}>{Strings.currentUserInfo}</h1>
          </Col>
          <Col span={12} align="right">
            <Button
              style={styles.editButton}
              type="primary"
              shape={"round"}
              icon={"user-delete"}
              size="large"
              onClick={() => {
                onDeallocateClick(currentUserInfo, inventory.id);
              }}
            >
              {Strings.deallocate}
            </Button>
          </Col>
        </Row>

        <Row gutter={24}>
          <InventoryItem
            title={Strings.projectName}
            description={currentUserInfo.projectName}
          />
          <InventoryItem
            title={Strings.allocatedAt}
            description={getDateAndTime(currentUserInfo.claimedAt)}
          />
        </Row>
        {currentUserInfo.users ? (
          <Col span={24} style={styles.col}>
            <Row gutter={8}>
              <Col span={4}>
                <span style={styles.titleText}>{Strings.users + " :"}</span>
              </Col>
              <Col span={20} style={styles.users}>
                <UsersTag {...currentUserInfo} />
              </Col>
            </Row>
          </Col>
        ) : null}
      </Card>
    );
  } else {
    return null;
  }
};

export default CurrentUserInfo;

const styles = {
  formTitleText: {
    fontSize: 22,
    fontWeight: "500",
    color: Colors.gray
  },
  card: {
    marginTop: 20
  },
  users: {
    marginTop: 10
  },
  titleText: {
    fontSize: 20
  }
};
