import React, { Component } from "react";
import FirebaseDBManager from "../firebase/FirebaseDBManager";
import "antd/dist/antd.css";
import LoginForm from "../components/forms/LoginForm";
import ForgotPasswordForm from "../components/forms/ForgotPasswordForm";
import { Col, Layout, Card, Row } from "antd";
import logo from "../images/app_login_logo.png";
import {
  logIn,
  forgotPassword,
  clearMessages,
  toggleForgotPassword
} from "../actions/AuthActions";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import "../extendedStyles/button.css";
import Colors from "../utils/Colors";
import { showErrorMessage, showSuccessMessage } from "../utils/ShowAlertDialog";
import Strings from "../utils/Strings";

class LoginContainer extends Component {
  componentWillReceiveProps(nextProps) {
    if (nextProps.loginError) {
      showErrorMessage(nextProps.loginError);
      nextProps.clearMessages();
    }
    if (nextProps.forgotPasswordSuccess) {
      showSuccessMessage(nextProps.forgotPasswordSuccess);
      nextProps.toggleForgotPassword(true);
      nextProps.clearMessages();
    }
  }

  componentDidMount() {
    FirebaseDBManager.init();
  }

  render() {
    return (
      <div style={styles.container}>
        <Layout style={styles.mainContainer}>
          <Card bodyStyle={styles.cardTitle} style={styles.card}>
            <Row>
              <Col span={2}>
                <img style={styles.logoStyle} src={logo} alt="Logo" />
              </Col>
              <Col align="center">
                <span style={styles.title}>{Strings.appName}</span>
              </Col>
            </Row>
          </Card>
          <Card style={styles.card}>
            {this.props.isLogin ? (
              <LoginForm {...this.props} />
            ) : (
              <ForgotPasswordForm {...this.props} />
            )}
          </Card>
        </Layout>
      </div>
    );
  }
}

const mapStateToProps = state => {
  state = state.auth;
  return {
    loginSuccess: state.loginSuccess,
    forgotPasswordSuccess: state.forgotPasswordSuccess,
    loginError: state.loginError,
    isLogin: state.isLogin
  };
};

LoginContainer.propTypes = {
  loginSuccess: PropTypes.string,
  loginError: PropTypes.string
};

export default connect(
  mapStateToProps,
  {
    logIn,
    forgotPassword,
    toggleForgotPassword,
    clearMessages
  }
)(LoginContainer);

const styles = {
  container: {
    flex: 1,
    backgroundColor: Colors.gray
  },
  logoStyle: {
    height: 45,
    marginLeft: 20
  },
  title: {
    fontWeight: "500",
    color: Colors.white,
    fontSize: 30,
    marginLeft: -70,
    width: 300,
    alignSelf: "center"
  },
  forgotPassword: {
    fontSize: 16,
    marginBottom: 20,
    marginTop: 10,
    color: Colors.appColor,
    borderBottomColor: "red",
    borderBottomWidth: 2
  },
  mainContainer: {
    flex: 1,
    height: "100vh",
    backgroundColor: Colors.lightGray,
    justifyContent: "center",
    alignItems: "center"
  },
  signInText: {
    fontSize: 26,
    fontWeight: "500",
    marginBottom: 20
  },
  inputTitle: {
    fontSize: 18,
    marginBottom: 10,
    color: Colors.gray
  },
  card: {
    width: "40%"
  },
  button: {
    marginTop: 100,
    marginBottom: 20
  },
  cardTitle: {
    padding: 10,
    margin: 0,
    backgroundColor: Colors.appColor
  }
};
