import {
  ADD_INVENTORY_ERROR,
  ADD_INVENTORY_SUCCESS,
  CLEAR_MESSAGES,
  EDIT_ENVENTORY
} from "../actions/ActionTypes";
import Constants from "../utils/Constants";
const INITIAL_STATE = {
  initialInventory: {
    inventoryInfo: {
      inventoryType: Constants.INVENTORY_TYPES[0]
    },
    purchaseInfo: {
      isClientDevice: false,
      isInsured: false
    }
  },
  isEdit: false
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ADD_INVENTORY_SUCCESS:
      return { ...state, addSuccess: action.addSuccess };
    case ADD_INVENTORY_ERROR:
      return { ...state, addError: action.addError };
    case CLEAR_MESSAGES:
      return { ...state, addSuccess: undefined, addError: undefined };
    case EDIT_ENVENTORY:
      return {
        ...state,
        isEdit: true,
        initialInventory: action.initialInventory
      };
    default:
      return state;
  }
};
