import {
  getDevices,
  getFilterDevices,
  getSearchDevices,
  requestToDeleteDevice,
  requestToRemoveListener,
  getDeviceHistory
} from "../services/DeviceListService";
import {
  DEVICES_SUCCESS,
  FILTER_VISIBLE,
  FILTER_TYPE,
  DEVICES_FETCH,
  DEVICES_FILTERED,
  DEVICE_DELETED_SUCCESS,
  DEVICE_DELETED_ERROR,
  CLEAR_MESSAGES,
  DEVICE_TRACKER_HISTORY_LOADING,
  CHANGE_PAGE_NUMBER,
  ON_SEARCH_DEVICE,
  DEVICE_TRACKER_HISTORY_SUCCESS,
  DEVICE_TRACKER_HISTORY_ERROR
} from "./ActionTypes";
import Constants from "../utils/Constants";
import Strings from "../utils/Strings";

export const toggleFilter = isVisible => dispatch => {
  return dispatch({ type: FILTER_VISIBLE, payload: isVisible });
};

export const filterDevices = isApply => {
  return function(dispatch, getState) {
    const devicesState = getState().devices;
    if (isApply) {
      const filterdDevices = getFilterDevices(
        devicesState.filterType,
        devicesState.storedDevices
      );
      return dispatch({
        type: DEVICES_FILTERED,
        devices: sort(filterdDevices),
        filterType: devicesState.filterType
      });
    } else {
      return dispatch({
        type: DEVICES_FILTERED,
        devices: devicesState.storedDevices,
        filterType: Constants.FILTER_CLEAR
      });
    }
  };
};

const sort = devices => {
  const claimedDevices = [];
  const unClaimedDevices = [];
  devices.forEach(device => {
    if (!device.claimedAt) {
      unClaimedDevices.push(device);
    } else {
      claimedDevices.push(device);
    }
  });
  claimedDevices.sort(function(a, b) {
    return b.claimedAt - a.claimedAt;
  });
  return claimedDevices.concat(unClaimedDevices);
};

export const removeListener = () => {
  requestToRemoveListener();
};

export const clearMessages = () => dispatch => {
  dispatch({
    type: CLEAR_MESSAGES
  });
};

export const changePageNumber = number => dispatch => {
  dispatch({
    type: CHANGE_PAGE_NUMBER,
    number: number
  });
};

export const searchDevices = searchText => (dispatch, getState) => {
  dispatch({ type: ON_SEARCH_DEVICE, searchText: searchText });
  requestToSearchDevice(searchText, dispatch, getState);
};

const requestToSearchDevice = (searchText, dispatch, getState) => {
  const devicesState = getState().devices;
  const filterdDevices = getFilterDevices(
    devicesState.filterType,
    devicesState.storedDevices
  );
  const searchedDevices = getSearchDevices(searchText, filterdDevices);
  dispatch({ type: DEVICES_SUCCESS, devices: searchedDevices });
};

export const deleteDevice = device => async dispatch => {
  try {
    await requestToDeleteDevice(device);
    dispatch({
      type: DEVICE_DELETED_SUCCESS,
      deviceDeletedSuccess: Strings.deviceDeletedSuccess
    });
  } catch (error) {
    console.log(error);
    dispatch({ type: DEVICE_DELETED_ERROR, deviceDeletedError: error });
  }
};

export const changeFilterType = filterType => dispatch => {
  return dispatch({ type: FILTER_TYPE, payload: filterType });
};

export const fetchDeviceHistory = deviceId => async dispatch => {
  try {
    dispatch({ type: DEVICE_TRACKER_HISTORY_LOADING });
    const deviceHistory = await getDeviceHistory(deviceId);
    dispatch({
      type: DEVICE_TRACKER_HISTORY_SUCCESS,
      deviceHistory: deviceHistory.reverse()
    });
  } catch (error) {
    console.log(error);
    dispatch({ type: DEVICE_TRACKER_HISTORY_ERROR, deviceHistoryError: error });
  }
};

export const fetchDevices = () => (dispatch, getState) => {
  dispatch({ type: ON_SEARCH_DEVICE, searchText: "" });
  return getDevices(devices => {
    const searchText = getState().devices.searchDeviceText;
    dispatch({ type: DEVICES_FETCH, devices: sort(devices) });
    requestToSearchDevice(searchText, dispatch, getState);
  });
};
