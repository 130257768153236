import React from "react";
import { Modal, Button } from "antd";
import Colors from "../../utils/Colors";
import Strings from "../../utils/Strings";
import Constants from "../../utils/Constants";
import PropTypes from "prop-types";
const FilterDialog = ({
  isVisible,
  toggleFilter,
  changeFilterType,
  selectedFilter,
  filterDevices
}) => {
  return (
    <Modal
      title={Strings.filter}
      visible={isVisible}
      onCancel={() => toggleFilter(false)}
      footer={[
        <Button key="back" onClick={() => filterDevices(false)}>
          {Strings.clear}
        </Button>,
        <Button key="submit" type="primary" onClick={() => filterDevices(true)}>
          {Strings.apply}
        </Button>
      ]}
    >
      <div
        onClick={() => changeFilterType(Constants.FILTER_CLAIMED_DEVICES)}
        style={
          selectedFilter === Constants.FILTER_CLAIMED_DEVICES
            ? styles.filterSelected
            : styles.filter
        }
      >
        {Strings.claimedDevices}
      </div>
      <div
        onClick={() => changeFilterType(Constants.FILTER_AVAILABLE_DEVICES)}
        style={
          selectedFilter === Constants.FILTER_AVAILABLE_DEVICES
            ? styles.filterSelected
            : styles.filter
        }
      >
        {Strings.availableDevices}
      </div>
    </Modal>
  );
};
export default FilterDialog;

FilterDialog.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  toggleFilter: PropTypes.func.isRequired,
  changeFilterType: PropTypes.func.isRequired,
  selectedFilter: PropTypes.number.isRequired,
  filterDevices: PropTypes.func.isRequired
};

const styles = {
  filterSelected: {
    padding: 15,
    color: Colors.black,
    fontSize: 16,
    backgroundColor: Colors.lightGray
  },
  filter: {
    color: Colors.black,
    fontSize: 16,
    padding: 15
  }
};
