import React from "react";
import { Modal, Button, Alert, Form } from "antd";
import Strings from "../../utils/Strings";
import PropTypes from "prop-types";
import { Formik } from "formik";
import FormInput from "../common/FormInput";
import { AddUserSchema } from "../../utils/ValidationSchemas";
const size = "large";

const AddUserDialog = props => {
  const { isVisible, toggleAddUser, addUserSuccess, addUserError } = props;
  const message = addUserSuccess ? addUserSuccess : addUserError;
  const type = addUserSuccess ? "success" : "error";
  return (
    <Modal
      title={Strings.addUser}
      visible={isVisible}
      onCancel={() => toggleAddUser(false)}
      footer={null}
    >
      {addUserSuccess || addUserError ? (
        <Alert style={styles.input} size={size} message={message} type={type} />
      ) : null}

      <AddUserForm {...props} />
    </Modal>
  );
};

const AddUserForm = formProps => (
  <Formik
    validationSchema={AddUserSchema}
    initialValues={{
      email: "",
      name: "",
      confirmPassword: "",
      password: ""
    }}
    onSubmit={(values, actions) => {
      formProps.addUser(values, actions);
    }}
    render={props => <AddUserFormInner {...props} />}
  />
);

const AddUserFormInner = props => {
  const {
    values,
    touched,
    errors,
    handleBlur,
    isSubmitting,
    handleChange,
    isValid,
    handleSubmit,
    setFieldValue
  } = props;

  return (
    <Form onSubmit={handleSubmit}>
      <FormInput
        id="email"
        value={values.email}
        placeholder={Strings.emailPlaceHolder}
        errorMessage={errors.email}
        type="email"
        handleBlur={handleBlur}
        onChange={e => {
          let text = e.target.value;
          const lastAlphabet = text[text.length - 1];
          if (!values.email.includes("@") && lastAlphabet === "@") {
            text = text + Strings.companyEmail; // if @ comes in text then concat official mail
          }
          setFieldValue("email", text);
        }}
      />
      <FormInput
        id="name"
        type="text"
        handleBlur={handleBlur}
        value={values.name}
        placeholder={Strings.namePlaceholder}
        errorMessage={touched.name && errors.name}
        onChange={handleChange}
      />
      <FormInput
        id="password"
        type="password"
        handleBlur={handleBlur}
        value={values.password}
        placeholder={Strings.passwordPlaceholder}
        errorMessage={touched.password && errors.password}
        onChange={handleChange}
      />
      <FormInput
        id="confirmPassword"
        type="password"
        handleBlur={handleBlur}
        value={values.confirmPassword}
        placeholder={Strings.confirmPasswordPlaceholder}
        errorMessage={touched.confirmPassword && errors.confirmPassword}
        onChange={handleChange}
      />
      <Button
        size={size}
        style={styles.button}
        type="primary"
        htmlType="submit"
        disabled={isSubmitting || !isValid}
        loading={isSubmitting}
      >
        {Strings.addUser}
      </Button>
    </Form>
  );
};

AddUserFormInner.propTypes = {
  values: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired,
  isValid: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired
};

AddUserDialog.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  toggleAddUser: PropTypes.func.isRequired,
  addUserSuccess: PropTypes.string.isRequired,
  addUserError: PropTypes.string.isRequired
};

export default AddUserDialog;

const styles = {
  input: {
    marginBottom: 15
  },
  button: {
    width: "100%",
    marginTop: 10
  }
};
