import React from "react";
import "antd/lib/menu/style";
import "antd/lib/icon/style";
import PropTypes from "prop-types";
import { Icon } from "antd";

const MenuIcon = ({ iconName }) => {
  return (
    <Icon type={iconName} style={styles} size={"large"} />
  );
};
export default MenuIcon;

MenuIcon.propTypes = {
  iconName: PropTypes.string.isRequired
};

const styles = {
  fontSize: "16px"
};
