import firebase from "firebase";
import ServerConstants from "../utils/ServerConstants";

export default class FirebaseDBManager {
  static database;
  static auth;
  static mFirebase;
  static init() {
    if (!firebase.apps.length) {
      firebase.initializeApp(ServerConstants.FIREBASE_CONFIG_PROD);
    } else {
      firebase.app();
    }
    FirebaseDBManager.mFirebase = firebase;
    FirebaseDBManager.database = firebase.database().ref();
    FirebaseDBManager.auth = firebase.auth();
  }
}
