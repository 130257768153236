import React from "react";
import { Row, Col } from "antd";
import Colors from "../../utils/Colors";
import PropTypes from "prop-types";
import Strings from "../../utils/Strings";

const InventoryInfoItem = ({ title, description, isStatus, isIssue }) => {
  return (
    <Col span={12} style={styles.col}>
      <Row gutter={24}>
        <Col span={8}>
          <div style={{ ...styles.titleText, ...styles.ellipsis }}>
            {title + " :"}
          </div>
        </Col>

        <Col span={16}>
          {isStatus !== undefined ? (
            <span
              style={
                isStatus === true
                  ? styles.descriptionActiveText
                  : styles.descriptionInActiveText
              }
            >
              {isStatus === true
                ? Strings.statusActive
                : Strings.statusInActive}
            </span>
          ) : (
            <span
              style={
                isIssue ? styles.descriptionIssueText : styles.descriptionText
              }
            >
              {description}
            </span>
          )}
        </Col>
      </Row>
    </Col>
  );
};
export default InventoryInfoItem;

InventoryInfoItem.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.any,
  isStatus: PropTypes.bool
};

const styles = {
  titleText: {
    fontSize: 18
  },
  ellipsis: {
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "noWrap"
  },
  descriptionText: {
    fontSize: 18,
    color: Colors.gray
  },
  descriptionIssueText: {
    fontSize: 18,
    color: Colors.lightRed
  },
  descriptionActiveText: {
    fontSize: 18,
    color: Colors.lightGreen
  },
  descriptionInActiveText: {
    fontSize: 18,
    color: Colors.lightRed
  },
  col: {
    marginTop: 10
  }
};
