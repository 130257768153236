import React from "react";
import { Row, Col } from "antd";
import "../../extendedStyles/listButton.css";
import Colors from "../../utils/Colors";
import UsersTag from "../common/UsersTag";
import { getDateAndTime } from "../../utils/DateUtils";
import PropTypes from "prop-types";

const InventoryHistoryCard = ({ inventoryHistory,isDevice }) => {
  return (
    <Row gutter={24}>
      <Col span={5}>
        <span style={styles.projectName}>{isDevice?inventoryHistory.claimedByName:inventoryHistory.projectName}</span>
      </Col>
      <Col span={9}>
        <UsersTag {...inventoryHistory} />
      </Col>
      <Col span={5}>
        <span style={styles.inventoryClaimedAt}>
          {getDateAndTime(inventoryHistory.claimedAt)}
        </span>
      </Col>
      <Col span={5}>
        <span style={styles.inventoryClaimedAt}>
          {getDateAndTime(inventoryHistory.relinquishAt)}
        </span>
      </Col>
    </Row>
  );
};

InventoryHistoryCard.propTypes = {
  inventoryHistory: PropTypes.object.isRequired,
  isDevice:PropTypes.bool
};

export default InventoryHistoryCard;

const styles = {
  projectName: {
    fontSize: 16,
    fontWeight: 500
  },
  inventoryClaimedAt: {
    fontSize: 16,
    color: Colors.gray
  }
};
