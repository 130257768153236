import { Tag } from "antd";
import React from "react";

 const UsersTag = currentUserInfo => {
  const usersTag = [];
  for (var key in currentUserInfo.users) {
    const userName = currentUserInfo.users[key].userName;
    usersTag.push(
      <Tag key={userName} size="large" color="red">
        {userName}
      </Tag>
    );
  }
  return usersTag;
}
export default UsersTag;