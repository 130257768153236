import React from "react";
import { Modal, Button, Alert, Form } from "antd";
import Strings from "../../utils/Strings";
import PropTypes from "prop-types";
import { Formik } from "formik";
import FormInput from "../common/FormInput";
import FormSwitch from "../common/FormSwitch";
import { showSuccessMessage } from "../../utils/ShowAlertDialog";
import { UpdateUserSchema } from "../../utils/ValidationSchemas";
const size = "large";

const EditUserDialog = props => {
  const { isVisible, toggleEditUser, addUserSuccess, addUserError } = props;
  const message = addUserSuccess ? addUserSuccess : addUserError;
  const type = addUserSuccess ? "success" : "error";

  if (addUserSuccess) {
    showSuccessMessage(addUserSuccess);
    toggleEditUser(false, {});
  }

  return (
    <Modal
      title={Strings.edit}
      visible={isVisible}
      onCancel={() => toggleEditUser(false, {})}
      footer={null}
    >
      {addUserError ? (
        <Alert style={styles.input} size={size} message={message} type={type} />
      ) : null}

      <EditUserForm {...props} />
    </Modal>
  );
};

const EditUserForm = formProps => (
  <Formik
    validationSchema={UpdateUserSchema}
    enableReinitialize={true}
    initialValues={formProps.user}
    onSubmit={(values, actions) => {
      formProps.updateUser(values, actions);
    }}
    render={props => <EditUserFormInner {...props} />}
  />
);

const EditUserFormInner = props => {
  const {
    values,
    touched,
    errors,
    handleBlur,
    isSubmitting,
    handleChange,
    isValid,
    handleSubmit,
    setFieldValue
  } = props;

  return (
    <Form onSubmit={handleSubmit}>
      <FormInput
        id="userName"
        type="text"
        handleBlur={handleBlur}
        value={values.userName}
        placeholder={Strings.namePlaceholder}
        errorMessage={touched.userName && errors.userName}
        onChange={handleChange}
      />
      <FormSwitch
        id="isAdmin"
        value={values.isAdmin}
        label={Strings.admin}
        setFieldValue={setFieldValue}
      />

      <Button
        size={size}
        style={styles.button}
        type="primary"
        htmlType="submit"
        disabled={isSubmitting || !isValid}
        loading={isSubmitting}
      >
        {Strings.save}
      </Button>
    </Form>
  );
};

EditUserFormInner.propTypes = {
  values: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired,
  isValid: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired
};

EditUserDialog.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  toggleAddUser: PropTypes.func.isRequired,
  addUserSuccess: PropTypes.string.isRequired,
  addUserError: PropTypes.string.isRequired
};

export default EditUserDialog;

const styles = {
  input: {
    marginBottom: 15
  },
  button: {
    width: "100%",
    marginTop: 10
  }
};
