const ServerConstants = {
  IOS_CLIENT_ID:
    "924632218851-l1v9kde6ie4469m04ptkucgp6j6c7a84.apps.googleusercontent.com",
  GOOGLE_AUTH_SCOPE: "https://www.googleapis.com/auth/userinfo.profile",
  FIREBASE_CONFIG_PROD: {
    apiKey: "AIzaSyBxFs6IqMnck4KsRsfuVDwukg-uSWfzgFY",
    authDomain: "mi-devicetracker-prod.firebaseapp.com",
    databaseURL: "https://mi-devicetracker-prod.firebaseio.com",
    projectId: "mi-devicetracker-prod",
    storageBucket: "",
    messagingSenderId: "161871529003"
  },
};

export default ServerConstants;
