import React, { Component } from "react";
import FirebaseDBManager from "../firebase/FirebaseDBManager";
import "antd/dist/antd.css";
import { Form } from "antd";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import "../extendedStyles/button.css";
import { Formik } from "formik";
import AddInventoryInfoForm from "../components/forms/AddInventoryInfoForm";
import AddInventoryPurchaseInfoForm from "../components/forms/AddInventoryPurchaseInfoForm";
import {
  addInventory,
  clearMessages,
  setInitialInventory
} from "../actions/AddInventoryActions";
import { AddInventorySchema } from "../utils/ValidationSchemas";
import { showSuccessMessage, showErrorMessage } from "../utils/ShowAlertDialog";
class AddInventory extends Component {
  componentDidMount() {
    FirebaseDBManager.init();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.addSuccess) {
      showSuccessMessage(nextProps.addSuccess);
      nextProps.clearMessages();
    }

    if (nextProps.addError) {
      showErrorMessage(nextProps.addError);
      nextProps.clearMessages();
    }
  }

  render() {
    return (
      <Formik
        validationSchema={AddInventorySchema}
        onSubmit={(values, actions) => {
          if (!values.inventoryInfo.lanMacAddress) {
            if (!values.inventoryInfo.macAddress) {
              showErrorMessage("Please enter lan mac address or mac address");
              actions.setSubmitting(false);
              return;
            }
          }
          this.props.addInventory(values, actions);
        }}
        enableReinitialize={true}
        initialValues={{
          inventoryInfo: {
            isActive: true
          },
          purchaseInfo: {
            isClientDevice: false,
            isInsured: false
          }
        }}
        render={props => {
          return (
            <Form onSubmit={props.handleSubmit}>
              <AddInventoryInfoForm {...props} />
              <AddInventoryPurchaseInfoForm {...props} />
            </Form>
          );
        }}
      />
    );
  }
}

const mapStateToProps = state => {
  state = state.addInventory;
  return {
    initialInventory: state.initialInventory,
    addSuccess: state.addSuccess,
    addError: state.addError
  };
};

AddInventory.propTypes = {
  initialInventory: PropTypes.object.isRequired,
  addSuccess: PropTypes.string,
  addError: PropTypes.string
};

export default connect(
  mapStateToProps,
  {
    addInventory,
    clearMessages,
    setInitialInventory
  }
)(AddInventory);
