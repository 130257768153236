import React, { Component } from "react";
import FirebaseDBManager from "../firebase/FirebaseDBManager";
import { List, Input, Row, Col, Button } from "antd";
import "antd/dist/antd.css";
import DeviceCard from "../components/listItems/DeviceCard";
import DeviceHeader from "../components/listItems/DeviceHeader";
import {
  fetchDevices,
  toggleFilter,
  changeFilterType,
  filterDevices,
  searchDevices,
  deleteDevice,
  clearMessages,
  removeListener,
  changePageNumber
} from "../actions/DeviceActions";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Strings from "../utils/Strings";
import FilterDialog from "../components/dialogs/FilterDialog";
import "../extendedStyles/button.css";
import { showSuccessAlert, showErrorAlert } from "../utils/ShowAlertDialog";
const Search = Input.Search;
const { Item } = List;
const size = "large";
class DeviceList extends Component {
  constructor() {
    super();
    this.toggleFilter = this.toggleFilter.bind(this);
    this.changeFilterType = this.changeFilterType.bind(this);
    this.filterDevices = this.filterDevices.bind(this);
  }
  componentDidMount() {
    FirebaseDBManager.init();
    this.getDevices();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.deviceDeletedSuccess) {
      showSuccessAlert(nextProps.deviceDeletedSuccess, () => {
        nextProps.clearMessages();
      });
    }

    if (nextProps.deviceDeletedError) {
      showErrorAlert(nextProps.deviceDeletedError, () => {
        nextProps.clearMessages();
      });
    }
  }

  componentWillUnmount() {
    removeListener();
  }

  getDevices = () => {
    this.props.fetchDevices();
  };

  toggleFilter(isVisible) {
    this.props.toggleFilter(isVisible);
  }

  changeFilterType(filterType) {
    this.props.changeFilterType(filterType);
  }

  filterDevices(isApply) {
    this.props.filterDevices(
      isApply,
      this.props.filterType,
      this.props.devices
    );
  }

  onChange = e => {
    this.props.searchDevices(e.target.value);
  };

  render() {

    return (
      <div>
        <Row>
          <Col span={12}>
            <Search
              value={this.props.searchDeviceText}
              placeholder={Strings.searchHere}
              size={size}
              allowClear={true}
              onChange={this.onChange}
              onSearch={text => this.props.searchDevices(text)}
              enterButton
            />
          </Col>

          <Col span={12} align="right">
            <Button
              type="primary"
              shape="round"
              icon="filter"
              size={size}
              onClick={() => this.toggleFilter(true)}
            >
              {Strings.filter}
            </Button>
          </Col>
        </Row>

        <List
          header={<DeviceHeader />}
          itemLayout="vertical"
          bordered={false}
          pagination={{
            showSizeChanger: false,
            defaultCurrent: 1,
            current: this.props.pageNumber,
            showTotal: total => ` Total: ${total}`,
            onChange: (page,pageSize) => {
              console.warn(pageSize);
              
              this.props.changePageNumber(page);
            }
          }}
          loading={this.props.isLoading}
          dataSource={this.props.devices}
          renderItem={item => (
            <Item style={styles.deviceItem}>
              <DeviceCard
                device={item}
                onDelete={this.props.deleteDevice}
                isAdmin={this.props.user.isAdmin}
              />
            </Item>
          )}
        />
        <FilterDialog
          toggleFilter={this.toggleFilter}
          changeFilterType={this.changeFilterType}
          selectedFilter={this.props.filterType}
          filterDevices={this.filterDevices}
          isVisible={this.props.isFilterVisible}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  const authState = state.auth;
  state = state.devices;
  return {
    searchDeviceText: state.searchDeviceText,
    devices: state.devices,
    isFilterVisible: state.isFilterVisible,
    filterType: state.filterType,
    deviceDeletedError: state.deviceDeletedError,
    deviceDeletedSuccess: state.deviceDeletedSuccess,
    isLoading: state.isLoading,
    pageNumber: state.pageNumber,
    user: authState.user
  };
};

export default connect(
  mapStateToProps,
  {
    fetchDevices,
    toggleFilter,
    changeFilterType,
    filterDevices,
    searchDevices,
    deleteDevice,
    clearMessages,
    changePageNumber
  }
)(DeviceList);

const styles = {
  deviceItem: {
    backgroundColor: "white",
    padding: 20
  },
  search: {
    width: "50%",
    borderRadius: 30
  },
  filterBtnView: {
    width: "100%",
    justifyContent: "flex-end"
  }
};

DeviceList.propTypes = {
  devices: PropTypes.array.isRequired,
  isFilterVisible: PropTypes.bool.isRequired,
  filterType: PropTypes.number.isRequired,
  deviceDeletedSuccess: PropTypes.string,
  deviceDeletedError: PropTypes.string,
  isLoading: PropTypes.bool.isRequired,
  pageNumber: PropTypes.number.isRequired
};
