import FirebaseDBManager from "../firebase/FirebaseDBManager";
import FirebaseDBConstants from "../utils/FirebaseDBConstants";
export const getUsers = callback => {
  FirebaseDBManager.database
    .child(FirebaseDBConstants.USERS_DB)
    .on("value", snapshot => {
      const users = [];
      if (snapshot.exists()) {
        snapshot.forEach(function(child) {
          users.push(child.val());
        });
      }
      callback(users);
    });
};

export const requestTodeleteUser = user => {
  return new Promise((resolve, reject) => {
    FirebaseDBManager.database
      .child(FirebaseDBConstants.USERS_DB)
      .child(user.userId)
      .remove()
      .then(() => {
        resolve();
      })
      .catch(error => reject(error));
  });
};

export const requestToRemoveListener = () => {
  FirebaseDBManager.database.child(FirebaseDBConstants.USERS_DB).off("value");
};

export const getSearchUsers = (searchText, users) => {
  try {
    return users.filter(user => {
      return (
        user.userName.toLowerCase().includes(searchText) ||
        user.userEmail.toLowerCase().includes(searchText)
      );
    });
  } catch (error) {
    console.log(error);
    return users;
  }
};
