import React from "react";
import { Row, Col, Button, Tag } from "antd";
import Colors from "../../utils/Colors";
import Strings from "../../utils/Strings";
import { showDeleteWarning } from "../../utils/ShowAlertDialog";
const size = "large";
const UserCard = ({ user, onDelete, toggleEditUser }) => {
  return (
    <Row gutter={16}>
      <Col span={7}>
        <span style={styles.userName}>{user.userName}</span>
      </Col>
      <Col span={10}>
        <span style={styles.userEmail}>{user.userEmail}</span>
      </Col>
      <Col span={3}>
        {user.isAdmin ? (
          <Tag key={"Admin"} size="large" color="red">
            {Strings.admin}
          </Tag>
        ) : null}
      </Col>
      <Col span={4} align="right">
        <Button
          style={styles.button}
          shape="circle"
          icon="edit"
          size={size}
          onClick={() => {
            toggleEditUser(true, user);
          }}
        />
        <Button
          style={styles.button}
          shape="circle"
          icon="delete"
          size={size}
          onClick={() => {
            showDeleteWarning(() => {
              onDelete(user);
            });
          }}
        />
      </Col>
    </Row>
  );
};

export default UserCard;

const styles = {
  button: {
    marginLeft: 10
  },
  userName: {
    fontSize: 16,
    fontWeight: 500
  },
  userEmail: {
    fontSize: 18,
    color: Colors.gray
  }
};
