import {
  requestToLogin,
  requestToForgotPassword
} from "../services/LoginService";
import {
  LOGIN_ERROR,
  LOGIN_SUCCESS,
  CLEAR_MESSAGES,
  LOGGED_IN,
  LOGGED_OUT,
  FORGOT_PASSWORD_VISBLE,
  FORGOT_PASSWORD_SUCCESS
} from "./ActionTypes";
import Strings from "../utils/Strings";

export const logIn = (user, actions) => async dispatch => {
  try {
    const loggedInUser = await requestToLogin(user);
    actions.setSubmitting(false);
    actions.setValues({ email: "" });
    dispatch({ type: LOGIN_SUCCESS, loginSuccess: loggedInUser });
  } catch (error) {
    console.log(error);
    actions.setSubmitting(false);
    actions.setValues({ email: "" });
    actions.setTouched({});
    dispatch({ type: LOGIN_ERROR, loginError: error });
  }
};

export const forgotPassword = (user, actions) => async dispatch => {
  try {
    await requestToForgotPassword(user);
    actions.setSubmitting(false);
    actions.setValues({ email: "" });
    dispatch({
      type: FORGOT_PASSWORD_SUCCESS,
      forgotPasswordSuccess: Strings.passwordResetLinkSuccess
    });
  } catch (error) {
    console.log(error);
    actions.setSubmitting(false);
    actions.setValues({ email: "" });
    actions.setTouched({});
    dispatch({ type: LOGIN_ERROR, loginError: error });
  }
};

export const logOut = () => dispatch => {
  dispatch({ type: LOGGED_OUT });
};

export const clearMessages = () => dispatch => {
  dispatch({ type: CLEAR_MESSAGES });
};

export const changeAunthenticationState = (isLoggedIn, user) => dispatch => {
  dispatch({ type: LOGGED_IN, isLoggedIn: isLoggedIn, user: user });
};

export const toggleForgotPassword = isLogin => dispatch => {
  dispatch({ type: FORGOT_PASSWORD_VISBLE, isLogin: isLogin });
};
