const Constants = {
  HOME_TAB: "home",
  LIST_TAB: "list",
  SETTINGS_TAB: "settings",
  PROFILE_TAB: "profile",
  MAIN_CONTAINER: "mainContainer",
  SPLASH_CONTAINER: "splash",
  PURCHASE_TYPE_ON_RENT: "On Rent",
  FORGOT_PASSWORD: "forgotPassword",
  LOGIN_CONTAINER: "loginContainer",
  USER_LIST: "userList",
  SESSION_USER: "user",
  SESSION_CLAIMED: "claimed",
  SESSION_DEVICE: "device",
  CLAIM_DEVICE: 1,
  RELINQUISH_DEVICE: 2,
  FILTER_AVAILABLE_DEVICES: 1,
  FILTER_CLAIMED_DEVICES: 2,
  FILTER_CLEAR: 0,
  PERMISSION_DENIED: "PERMISSION_DENIED",
  USER_NOT_FOUND: "auth/user-not-found",
  WRONG_PASSWORD: "auth/wrong-password",
  INVENTORY_TYPES: [
    "Phone",
    "Tablet",
    "iPad",
    "Desktop",
    "Laptop",
    "Mac Book",
    "Mac Mini",
    "iMac"
  ],
  AVAILABILITY_TYPES: ["Allocated Devices", "Available Devices"],
  MEMORY_SIZES: [
    "512 MB",
    "1 GB",
    "2 GB",
    "4 GB",
    "8 GB",
    "12 GB",
    "16 GB",
    "32 GB",
    "64 GB"
  ],
  OS: [
    "Android",
    "iOS",
    "Windows",
    "MAC",
    "Ubuntu",
    "Red Hat",
    "Cent OS",
    "Black Berry"
  ],
  INVENTORY_STATUS: ["Active", "Inactive"],
  PURCHASE_TYPES: ["New Purchased", "From Client", "On Rent", "Second Hand"]
};

export default Constants;
