import React from "react";
import { Select, Form } from "antd";
import PropTypes from "prop-types";
const { Option } = Select;
const { Item } = Form;
const size = "large";
const FormSelect = props => {
  const {
    label,
    id,
    placeholder,
    value,
    options,
    setFieldValue,
    isRequired
  } = props;
  return (
    <Item label={label} required={isRequired}>
      <Select
        size={size}
        value={value}
        style={styles.select}
        placeholder={placeholder}
        onSelect={value => {
          setFieldValue(id, value);
        }}
      >
        {getOptions(options)}
      </Select>
    </Item>
  );
};

const getOptions = options => {
  const inventoryOptions = [];
  for (let i = 0; i < options.length; i++) {
    inventoryOptions.push(
      <Option key={options[i]} value={options[i]}>
        {options[i]}
      </Option>
    );
  }
  return inventoryOptions;
};

const styles = {
  select: {
    width: "100%"
  }
};

FormSelect.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.string,
  isRequired: PropTypes.bool
};

export default FormSelect;
