import {
  DEVICES_SUCCESS,
  FILTER_VISIBLE,
  FILTER_TYPE,
  DEVICES_FETCH,
  DEVICES_FILTERED,
  DEVICE_DELETED_ERROR,
  DEVICE_DELETED_SUCCESS,
  CLEAR_MESSAGES,
  ON_SEARCH_DEVICE,
  CHANGE_PAGE_NUMBER,
  DEVICE_TRACKER_HISTORY_LOADING,
  DEVICE_TRACKER_HISTORY_SUCCESS,
  DEVICE_TRACKER_HISTORY_ERROR,
} from "../actions/ActionTypes";
const INITIAL_STATE = {
  devices: [],
  isFilterVisible: false,
  filterType: 0,
  storedDevices: [],
  isLoading: true,
  searchDeviceText: "",
  pageNumber: 1,
  deviceHistory: []
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case DEVICES_FETCH:
      return { ...state, storedDevices: action.devices, isLoading: false };
    case DEVICES_SUCCESS:
      return {
        ...state,
        isHistory: false,
        devices: action.devices,
        pageNumber: state.isHistory ? state.pageNumber : 1
      };
    case ON_SEARCH_DEVICE:
      return { ...state, searchDeviceText: action.searchText };
    case FILTER_VISIBLE:
      return { ...state, isFilterVisible: action.payload };
    case DEVICE_DELETED_SUCCESS:
      return { ...state, deviceDeletedSuccess: action.deviceDeletedSuccess };
    case DEVICE_DELETED_ERROR:
      return { ...state, deviceDeletedError: action.deviceDeletedError };
    case FILTER_TYPE:
      return { ...state, filterType: action.payload };
    case CHANGE_PAGE_NUMBER:
      return { ...state, pageNumber: action.number };
    case DEVICE_TRACKER_HISTORY_SUCCESS:
      return {
        ...state,
        deviceHistory: action.deviceHistory,
        isHistoryLoading: false
      };
    case DEVICE_TRACKER_HISTORY_LOADING:
      return {
        ...state,
        deviceHistory: [],
        isHistoryLoading: true,
        isHistory: true
      };
    case DEVICE_TRACKER_HISTORY_ERROR:
      return { ...state, deviceHistoryError: action.deviceHistoryError };
    case CLEAR_MESSAGES:
      return {
        ...state,
        deviceDeletedError: undefined,
        deviceDeletedSuccess: undefined
      };
    case DEVICES_FILTERED:
      return {
        ...state,
        filterType: action.filterType,
        devices: action.devices,
        isFilterVisible: false,
        pageNumber: 1
      };
    default:
      return state;
  }
};
