import React from "react";
import PropTypes from "prop-types";
import { Avatar, Popover, Row } from "antd";
import Colors from "../../utils/Colors";
import Strings from "../../utils/Strings";
import "antd/lib/grid/style";
import "antd/lib/avatar/style";
import "antd/lib/badge/style";
import "antd/lib/popover/style";

const HeaderProfile = props => {
  const popoverContents = (
    <div>
      <div>
        <div style={styles.signOutText} onClick={props.onLogOut}>
          {Strings.signOut}
        </div>
      </div>
    </div>
  );

  return (
    <Popover placement="bottomRight" content={popoverContents} trigger="click">
      <Row>
        <Avatar shape="square" icon="user" />
        <span style={styles.profileName}>{props.name}</span>
      </Row>
    </Popover>
  );
};

HeaderProfile.propTypes = {
  name: PropTypes.string
};

const styles = {
  profileName: {
    color: Colors.white,
    fontSize: 16,
    marginLeft: 15
  },
  signOutText: {
    color: Colors.lightBlue,
    "&:hover": {
      textDecoration: "underline"
    }
  }
};

export default HeaderProfile;
