import Strings from "./Strings";
import * as Yup from "yup";
const regx = /^\w+([\.-]?\w+)*@\mobisoftinfotech.com$/;

export const AddUserSchema = Yup.object().shape({
  email: Yup.string()
    .required(Strings.emailValidationError)
    .test("isSecure", Strings.emailValidationError, value => {
      return value ? value.match(regx) : true;
    }),
  password: Yup.string()
    .trim()
    .required(Strings.passwordBlankError)
    .min(6, Strings.passwordToShort),
  name: Yup.string()
    .trim()
    .required(Strings.nameValidationError),
  confirmPassword: Yup.string()
    .trim()
    .oneOf([Yup.ref("password")], Strings.confirmPasswordNotMatch)
    .required(Strings.confirmPasswordBlankError)
});

export const AllocateInventorySchema = Yup.object().shape({
  projectName: Yup.string()
    .trim()
    .required(Strings.projectNameBlankError),
  users: Yup.object()
});

export const AddInventoryHistorySchema = Yup.object().shape({
  projectName: Yup.string()
    .trim()
    .required(Strings.projectNameBlankError),
  users: Yup.object(),
  claimedAt: Yup.string().required(),
  relinquishAt: Yup.string().required()
});

export const LoginValidationSchema = Yup.object().shape({
  email: Yup.string()
    .required(Strings.emailValidationError)
    .test("isSecure", Strings.emailValidationError, value => {
      return value ? value.match(regx) : true;
    }),
  password: Yup.string()
    .trim()
    .required(Strings.passwordBlankError)
    .min(6, Strings.passwordToShort)
});

export const AddInventorySchema = Yup.object().shape({
  inventoryInfo: Yup.object().shape({
    inventoryId: Yup.string()
      .trim()
      .required(Strings.inventoryFieldError),
    inventoryType: Yup.string()
      .trim()
      .required(Strings.inventoryFieldError),
    inventoryOS: Yup.string()
      .trim()
      .required(Strings.inventoryFieldError),
    manufacturerName: Yup.string()
      .trim()
      .required(Strings.inventoryFieldError)
  }),
  purchaseInfo: Yup.object().shape({
    purchaseDate: Yup.string()
      .trim()
      .required(Strings.inventoryFieldError),
    purchaseType: Yup.string()
      .trim()
      .required(Strings.inventoryFieldError)
  })
});

export const ForgotPasswordValidationSchema = Yup.object().shape({
  email: Yup.string(Strings.emailValidationError)
    .required(Strings.emailValidationError)
    .test("isSecure", Strings.emailValidationError, value => {
      return value ? value.match(regx) : true;
    })
});

export const AddDeviceSchema = Yup.object().shape({
  deviceName: Yup.string()
    .trim()
    .required(Strings.deviceNameValidationError),
  deviceId: Yup.string()
    .trim()
    .required(Strings.deviceIdValidationError),
  deviceVersion: Yup.string()
    .trim()
    .required(Strings.deviceVersionValidationError),
  deviceOS: Yup.string().required()
});

export const UpdateUserSchema = Yup.object().shape({
  userName: Yup.string()
    .trim()
    .required(Strings.nameValidationError)
});
