import React from "react";
import { Input, Form, Col } from "antd";
import PropTypes from "prop-types";
const { Item } = Form;
const size = "large";
const { TextArea } = Input;
const TextAreaFormInput = props => {
  return (
    <Col span={16} style={styles.formInput}>
      <Item
        label={props.label}
        required={props.isRequired}
        validateStatus={props.errorMessage ? "error" : ""}
        help={props.errorMessage}
      >
        <TextArea
          style={styles.input}
          label={props.id}
          name={props.id}
          autosize={{ minRows: 2, maxRows: 6 }}
          onBlur={props.handleBlur}
          type={props.type}
          placeholder={props.placeholder}
          size={size}
          value={props.value}
          onChange={props.handleChange}
        />
      </Item>
    </Col>
  );
};

const styles = {
  input: {
    marginBottom: 5
  },
  formInput: {
    paddingRight: 20
  }
};

TextAreaFormInput.propTypes = {
  id: PropTypes.string.isRequired,
  value: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.string
};

export default TextAreaFormInput;
