import FirebaseDBConstants from "../utils/FirebaseDBConstants";
import FirebaseDBManager from "../firebase/FirebaseDBManager";
import firebase from "firebase";
import Strings from "../utils/Strings";
import Constants from "../utils/Constants";

export const requestToLogin = user => {
  return new Promise((resolve, reject) => {
    firebase
      .auth()
      .signInWithEmailAndPassword(user.email, user.password)
      .then(data => {
        resolve(getUserFromFirebaseDB(data.user.uid, true));
      })
      .catch(error => {
        console.warn(error.code);
        if (
          (error.code !== undefined &&
            error.code === Constants.USER_NOT_FOUND) ||
          error.code === Constants.WRONG_PASSWORD
        ) {
          reject(Strings.invalidLoginCredentials);
        } else {
          reject(error.message);
        }
      });
  });
};

export const logOut = () => {
  return new Promise((resolve, reject) => {
    firebase
      .auth()
      .signOut()
      .then(data => {
        resolve();
      })
      .catch(error => {
        reject(error.message);
      });
  });
};

const getUserFromFirebaseDB = (userId, isLogin) => {
  return new Promise((resolve, reject) => {
    FirebaseDBManager.database
      .child(FirebaseDBConstants.USERS_DB)
      .child(userId)
      .once("value")
      .then(function(snapshot) {
        if (snapshot.exists()) {
          resolve(snapshot.val());
        } else {
          reject(Strings.userDeleted);
        }
      })
      .catch(error => {
        if (isLogin) {
          reject(error.message);
        } else {
          reject(error);
        }
      });
  });
};

export const requestToForgotPassword = user => {
  return new Promise((resolve, reject) => {
    firebase
      .auth()
      .sendPasswordResetEmail(user.email)
      .then(data => {
        resolve();
      })
      .catch(error => {
        reject(error.message);
      });
  });
};

export const saveUser = user => {
  FirebaseDBManager.database
    .child(FirebaseDBConstants.USERS_DB)
    .child(user.uid)
    .set({
      userName: user.displayName,
      userId: user.uid,
      userEmail: user.email,
      isActive: true,
      isAdmin: false,
      userPhotoUrl: user.photoURL
    })
    .then(data => {
      this.successResponse();
    })
    .catch(error => {
      this.error(error);
    });
};
