import {
  INVENTORY_FETCH_SUCCESS,
  INVENTORY_FETCH_ERROR,
  INVENTORY_HISTORY__SUCCESS,
  INVENTORY_HISTORY_ERROR,
  INVENTORY_DELETED_SUCCESS,
  INVENTORY_DELETED_ERROR,
  CLEAR_MESSAGES,
  USERS_FETCH,
  ALLOCATE_INVENTORY_SUCCESS,
  ALLOCATE_INVENTORY_ERROR,
  DE_ALLOCATE_INVENTORY_SUCCESS,
  DE_ALLOCATE_INVENTORY_ERROR,
  ALLOCATE_INVENTORY_VISIBLE,
  CLEAR_HISTORY,
  IS_HISTORY_SELECTED
} from "../actions/ActionTypes";
const INITIAL_STATE = {
  isAllocateInventoryVisible: false,
  users: [],
  inventoryHistory: [],
  isHistoryLoading: true
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case INVENTORY_FETCH_SUCCESS:
      return {
        ...state,
        inventory: action.inventory,
        inventoryHistory: [],
        isHistorySelected: true
      };
    case IS_HISTORY_SELECTED: {
      return { ...state, isHistorySelected: false };
    }
    case INVENTORY_FETCH_ERROR:
      return {
        ...state,
        inventoryError: action.inventoryError,
        isHistorySelected: true
      };
    case CLEAR_MESSAGES:
      return {
        ...state,
        inventoryError: undefined,
        allocateInventoryError: undefined,
        allocateInventorySuccess: undefined,
        inventoryDeletedError: undefined,
        inventoryDeletedSuccess: undefined,
        deallocateInventoryError: undefined,
        deallocateInventorySuccess: undefined
      };
    case ALLOCATE_INVENTORY_VISIBLE:
      return {
        ...state,
        isAllocateInventoryVisible: action.payload,
        allocateInventoryError: undefined,
        allocateInventorySuccess: undefined
      };
    case ALLOCATE_INVENTORY_SUCCESS:
      return {
        ...state,
        allocateInventorySuccess: action.allocateInventorySuccess,
        isAllocateInventoryVisible: false
      };
    case DE_ALLOCATE_INVENTORY_SUCCESS:
      return {
        ...state,
        deallocateInventorySuccess: action.deallocateInventorySuccess
      };
    case DE_ALLOCATE_INVENTORY_ERROR:
      return {
        ...state,
        deallocateInventoryError: action.deallocateInventoryError
      };
    case ALLOCATE_INVENTORY_ERROR:
      return {
        ...state,
        allocateInventoryError: action.allocateInventoryError
      };
    case USERS_FETCH:
      return { ...state, users: action.users };
    case INVENTORY_HISTORY__SUCCESS:
      return {
        ...state,
        inventoryHistory: action.inventoryHistory,
        isHistoryLoading: false
      };
    case INVENTORY_HISTORY_ERROR:
      return { ...state, inventoryHistoryError: action.inventoryHistoryError };
    case INVENTORY_DELETED_SUCCESS:
      return {
        ...state,
        inventoryDeletedSuccess: action.inventoryDeletedSuccess
      };
    case INVENTORY_DELETED_ERROR:
      return { ...state, inventoryDeletedError: action.inventoryDeletedError };
    case CLEAR_HISTORY:
      return { ...state, inventoryHistory: [] };
    default:
      return state;
  }
};
