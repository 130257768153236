import {
  getInventories,
  getFilterInventories,
  getSearchInventories,
  requestToRemoveListener
} from "../services/InventoryListService";
import {
  INVENTORY_FILTER_VISIBLE,
  CLEAR_MESSAGES,
  INVENTORIES_FILTERED,
  INVENTORIES_FETCH_SUCCESS,
  CHANGE_INVENTORY_PAGE_NUMBER,
  IS_HISTORY_SELECTED
} from "./ActionTypes";

export const toggleFilter = isVisible => dispatch => {
  return dispatch({ type: INVENTORY_FILTER_VISIBLE, payload: isVisible });
};

export const filterInventories = filter => {
  return function(dispatch, getState) {
    const devicesState = getState().inventories;
    if (true) {
      const filterdInventories = getFilterInventories(
        filter,
        devicesState.storedInventories
      );
      return dispatch({
        type: INVENTORIES_FILTERED,
        inventories: filterdInventories
      });
    }
  };
};

export const changePageNumber = number => dispatch => {
  dispatch({
    type: CHANGE_INVENTORY_PAGE_NUMBER,
    number: number
  });
};

export const removeListener = () => {
  requestToRemoveListener();
};

export const clearMessages = () => dispatch => {
  dispatch({
    type: CLEAR_MESSAGES
  });
};

export const searchInventories = searchText => (dispatch, getState) => {
  const devicesState = getState().inventories;
  const searchedDevices = getSearchInventories(
    searchText,
    devicesState.storedInventories
  );
  dispatch({ type: INVENTORIES_FILTERED, inventories: searchedDevices });
};

export const fetchInventories = () => {
  return function(dispatch, getState) {
    return getInventories(inventories => {
      const pageNumber = getState().inventories.pageNumber;
      const isHistorySelected = getState().inventoryDetail.isHistorySelected;
      dispatch({ type: IS_HISTORY_SELECTED });
      dispatch({
        type: INVENTORIES_FETCH_SUCCESS,
        inventories: inventories,
        pageNumber: isHistorySelected ? pageNumber : 1
      });
    });
  };
};
