import { Row } from "antd";
import React from "react";
import InventoryItem from "../common/InventoryInfoItem";
import Colors from "../../utils/Colors";
import Strings from "../../utils/Strings";

const InventoryInfo = ({ inventory }) => {
  return (
    <div>
      <h1 style={styles.formTitleText}>{Strings.inventoryInfo}</h1>

      <Row gutter={8}>
        <InventoryItem
          title={Strings.inventoryId}
          description={inventory.inventoryId}
        />
        <InventoryItem
          title={Strings.status}
          isStatus={inventory.isActive}
          description={inventory.inventoryId}
        />
        <InventoryItem
          title={Strings.inventoryType}
          description={inventory.inventoryType}
        />
        <InventoryItem
          title={Strings.companyName}
          description={inventory.manufacturerName}
        />
        <InventoryItem
          title={Strings.inventoryOS}
          description={inventory.inventoryOS}
        />
        <InventoryItem
          title={Strings.modelNumber}
          description={inventory.modelNumber}
        />
        <InventoryItem
          title={Strings.serialNumber}
          description={inventory.serialNumber}
        />
        <InventoryItem
          title={Strings.staticIpAddress}
          description={inventory.staticIpAddress}
        />
        <InventoryItem
          title={Strings.macAddress}
          description={inventory.macAddress}
        />
        <InventoryItem
          title={Strings.imeiNumber}
          description={inventory.imeiNumber}
        />
        <InventoryItem
          title={Strings.memorySize}
          description={inventory.memorySize}
        />
        <InventoryItem
          title={Strings.lanMacAddress}
          description={inventory.lanMacAddress}
        />
        <InventoryItem
          title={Strings.deviceTrackerStatus}
          description={
            inventory.deviceTrackerStatus
              ? Strings.installed
              : Strings.unInstalled
          }
        />
        <InventoryItem
          isIssue
          title={Strings.issue}
          description={inventory.issue}
        />

        <InventoryItem title={Strings.remark} description={inventory.remark} />
      </Row>
    </div>
  );
};

export default InventoryInfo;
const styles = {
  formTitleText: {
    fontSize: 22,
    fontWeight: "500",
    color: Colors.gray
  },
  titleText: {
    fontSize: 18
  },
  issueText: {
    fontSize: 20,
    color: Colors.lightRed
  }
};
