import React from "react";
import { Modal, Button, Alert, Form, Select, DatePicker } from "antd";
import Strings from "../../utils/Strings";
import PropTypes from "prop-types";
import { Formik } from "formik";
import FormInput from "../common/FormInput";
import {
  AllocateInventorySchema,
  AddInventoryHistorySchema
} from "../../utils/ValidationSchemas";
import Moment from "moment";
const { Option } = Select;
const { Item } = Form;
const size = "large";
const AllocateInventoryDialog = props => {
  const {
    isHistory,
    isVisible,
    toggleAllocateInventory,
    allocateInventorySuccess,
    allocateInventoryError
  } = props;
  const message = allocateInventorySuccess
    ? allocateInventorySuccess
    : allocateInventoryError;
  const type = allocateInventorySuccess ? "success" : "error";
  return (
    <Modal
      title={isHistory ? Strings.addHistory : Strings.allocateDevice}
      visible={isVisible}
      onCancel={() => toggleAllocateInventory(false)}
      footer={null}
      maskClosable={false}
    >
      {allocateInventorySuccess || allocateInventoryError ? (
        <Alert style={styles.input} size={size} message={message} type={type} />
      ) : null}

      <AllocateInventoryForm {...props} />
    </Modal>
  );
};

const AllocateInventoryForm = formProps => {
  return (
    <Formik
      validationSchema={
        formProps.isHistory
          ? AddInventoryHistorySchema
          : AllocateInventorySchema
      }
      onSubmit={(values, actions) => {
        formProps.onAllocateInventory(values, actions);
      }}
      render={props => {
        props.users = formProps.users;
        props.inventory = formProps.inventory;
        props.isHistory = formProps.isHistory;
        return <AllocateInventoryFormInner {...props} />;
      }}
    />
  );
};

const AllocateInventoryFormInner = props => {
  const {
    values,
    errors,
    handleBlur,
    isSubmitting,
    handleChange,
    isValid,
    handleSubmit,
    users,
    setFieldValue,
    isHistory,
    inventory
  } = props;
  const children = [];
  for (let i = 0; i < users.length; i++) {
    children.push(<Option key={users[i].userId}>{users[i].userName}</Option>);
  }
  let selectedOptions = [];
  if (values.users) {
    selectedOptions = Object.keys(values.users);
  }
  const disabledAllocatedDate = current => {
    if (inventory.purchaseInfo.purchaseDate) {
      return current && current < inventory.purchaseInfo.purchaseDate;
    } else {
      return current < Moment().startOf("day");
    }
  };

  const disabledDeAllocatedDate = current => {
    if (values.claimedAt) {
      return current && current < values.claimedAt;
    } else {
      return current < Moment().startOf("day");
    }
  };

  return (
    <Form onSubmit={handleSubmit}>
      <FormInput
        id="projectName"
        isRequired
        label={Strings.projectName}
        value={values.projectName}
        placeholder={Strings.projectNamePlaceHolder}
        errorMessage={errors.projectName}
        handleBlur={handleBlur}
        onChange={handleChange}
      />
      <Item
        label={Strings.users}
        validateStatus={errors.users ? "error" : ""}
        help={errors.users}
      >
        <Select
          mode="multiple"
          size={size}
          value={selectedOptions}
          showArrow={true}
          placeholder="Please select"
          onChange={(value, option) => {
            const selectedValues = {};
            option.forEach(selectedValue => {
              selectedValues[selectedValue.key] = {
                userId: selectedValue.key,
                userName: selectedValue.props.children
              };
            });
            var isSelectedValuesEmpty = !Object.keys(selectedValues).length;
            if (isSelectedValuesEmpty) {
              setFieldValue("users", undefined);
            } else {
              setFieldValue("users", selectedValues);
            }
          }}
          style={styles.select}
        >
          {children}
        </Select>
      </Item>
      {isHistory ? (
        <div>
          <Item label={Strings.allocatedAt} required>
            <DatePicker
              format={"DD-MM-YYYY"}
              style={styles.dateInput}
              value={values.claimedAt ? Moment(values.claimedAt) : undefined}
              size={size}
              disabledDate={disabledAllocatedDate}
              onChange={date => {
                props.setFieldValue(
                  "claimedAt",
                  date ? date.valueOf() : undefined
                );
              }}
            />
          </Item>
          <Item label={Strings.deallocatedAt} required>
            <DatePicker
              format={"DD-MM-YYYY"}
              style={styles.dateInput}
              value={
                values.relinquishAt ? Moment(values.relinquishAt) : undefined
              }
              disabledDate={disabledDeAllocatedDate}
              size={size}
              onChange={date => {
                props.setFieldValue(
                  "relinquishAt",
                  date ? date.valueOf() : undefined
                );
              }}
            />
          </Item>
        </div>
      ) : null}

      <Button
        size={size}
        style={styles.button}
        type="primary"
        htmlType="submit"
        disabled={isSubmitting || !isValid}
        loading={isSubmitting}
      >
        {isHistory ? Strings.addHistory : Strings.allocateDevice}
      </Button>
    </Form>
  );
};

AllocateInventoryFormInner.propTypes = {
  values: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired,
  isValid: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  users: PropTypes.array.isRequired
};
AllocateInventoryDialog.propTypes = {
  isHistory: PropTypes.any,
  isVisible: PropTypes.bool.isRequired,
  toggleAllocateInventory: PropTypes.func.isRequired,
  allocateInventorySuccess: PropTypes.string,
  allocateInventoryError: PropTypes.string
};
export default AllocateInventoryDialog;

const styles = {
  input: {
    marginBottom: 15,
    width: "100%"
  },
  dateInput: {
    width: "100%"
  },
  button: {
    width: "100%",
    marginTop: 30
  },
  select: {
    width: "100%"
  }
};
