import { Row, Card } from "antd";
import React from "react";
import InventoryItem from "../common/InventoryInfoItem";
import Colors from "../../utils/Colors";
import Constants from "../../utils/Constants";
import Strings from "../../utils/Strings";
import { getDate } from "../../utils/DateUtils";
const PurchaseInfo = ({ inventory }) => {
  const purchaseInfo = inventory.purchaseInfo;
  return (
    <Card style={styles.card}>
      <h1 style={styles.formTitleText}>{Strings.purchaseInfo}</h1>
      <Row gutter={24}>
        <InventoryItem
          title={Strings.purchaseDate}
          description={getDate(purchaseInfo.purchaseDate)}
        />
        <InventoryItem
          title={Strings.vendorName}
          description={purchaseInfo.vendorName}
        />

        <InventoryItem
          title={Strings.purchaseType}
          description={purchaseInfo.purchaseType}
        />
        <InventoryItem
          title={Strings.insuranceStatus}
          description={
            purchaseInfo.isInsured ? Strings.insured : Strings.notInsured
          }
        />
        {purchaseInfo.purchaseType === Constants.PURCHASE_TYPE_ON_RENT ? (
          <InventoryItem
            title={Strings.returnDate}
            description={getDate(purchaseInfo.returnDate)}
          />
        ) : null}
      </Row>
    </Card>
  );
};

export default PurchaseInfo;

const styles = {
  formTitleText: {
    fontSize: 22,
    fontWeight: "500",
    color: Colors.gray
  },
  card: {
    marginTop: 20
  }
};
