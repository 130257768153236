import React from "react";
import { Switch, Form, Col } from "antd";
import PropTypes from "prop-types";
import Strings from "../../utils/Strings";
const { Item } = Form;
const size = "large";
const FormSwitch = props => {
  const { label, value, id, setFieldValue, inventoryStatus, span } = props;
  return (
    <Col span={span ? span : 4} style={styles.formInput}>
      <Item label={label}>
        <Switch
          size={size}
          checkedChildren={inventoryStatus ? Strings.statusActive : ""}
          unCheckedChildren={inventoryStatus ? Strings.statusInActive : ""}
          checked={value}
          onChange={checkedChildren => {
            setFieldValue(id, checkedChildren);
          }}
        />
      </Item>
    </Col>
  );
};

const styles = {
  formInput: {
    paddingRight: 20
  }
};

FormSwitch.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.bool
};

export default FormSwitch;
