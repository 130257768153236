import {
  LOGIN_SUCCESS,
  LOGIN_ERROR,
  CLEAR_MESSAGES,
  LOGGED_IN,
  FORGOT_PASSWORD_VISBLE,
  FORGOT_PASSWORD_SUCCESS,
  LOGGED_OUT
} from "../actions/ActionTypes";
const INITIAL_STATE = {
  isLogin: true,
  isLoggedIn: true,
  user: {}
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOGIN_SUCCESS:
      return { ...state, loginSuccess: action.loginSuccess };
    case LOGIN_ERROR:
      return { ...state, loginError: action.loginError };
    case LOGGED_IN:
      return { ...state, isLoggedIn: action.isLoggedIn, user: action.user };
    case LOGGED_OUT:
      return { ...state, isLoggedIn: false, user: undefined };
    case CLEAR_MESSAGES:
      return {
        ...state,
        loginSuccess: undefined,
        loginError: undefined,
        forgotPasswordSuccess: undefined
      };
    case FORGOT_PASSWORD_VISBLE:
      return { ...state, isLogin: action.isLogin };
    case FORGOT_PASSWORD_SUCCESS:
      return { ...state, forgotPasswordSuccess: action.forgotPasswordSuccess };

    default:
      return state;
  }
};
