import React from "react";
import { Input, Form, Col } from "antd";
import PropTypes from "prop-types";
const { Item } = Form;
const size = "large";
const InventoryFormInput = props => {
  return (
    <Col span={8}>
      <Item label={props.label} required={props.isRequired}>
        <Input
          style={props.isUpperCase ? styles.upperCaseInput : styles.input}
          label={props.id}
          name={props.id}
          onBlur={props.handleBlur}
          type={props.type}
          placeholder={props.placeholder}
          size={size}
          value={props.value}
          onChange={props.handleChange}
        />
      </Item>
    </Col>
  );
};

const styles = {
  input: {
    marginBottom: 5
  },
  upperCaseInput: {
    marginBottom: 5,
    textTransform: "uppercase"
  }
};

InventoryFormInput.propTypes = {
  id: PropTypes.string.isRequired,
  value: PropTypes.any,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.any,
  isUpperCase: PropTypes.any
};

export default InventoryFormInput;
