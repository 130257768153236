import React from "react";
import { DatePicker, Form, Col } from "antd";
import PropTypes from "prop-types";
import Moment from "moment";
const { Item } = Form;
const size = "large";
const InventoryFormDatePicker = props => {  
  return (
    <Col span={8}>
      <Item label={props.label} required={props.isRequired}>
        <DatePicker
          format={"DD-MM-YYYY"}
          value={props.value ? Moment(props.value) : undefined}
          size={size}
          disabledDate={props.disabledDate}
          style={styles.input}
          label={props.id}
          placeholder={props.placeholder}
          onChange={date => {
            props.setFieldValue(props.id, date ? date.valueOf() : undefined);
          }}
        />
      </Item>
    </Col>
  );
};

const styles = {
  input: {
    marginBottom: 5,
    width: "100%"
  }
};

InventoryFormDatePicker.propTypes = {
  id: PropTypes.string.isRequired,
  value: PropTypes.any,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.any,
  isUpperCase: PropTypes.any
};

export default InventoryFormDatePicker;
