import Moment from "moment";

export const getDate = claimedAt => {
  let date = "";
  if (claimedAt !== undefined) {
    date = Moment(claimedAt).format("DD MMM YYYY");
  }
  return date;
};


export const getDateAndTime = claimedAt => {
  let date = "";
  if (claimedAt !== undefined) {
    date = Moment(claimedAt).format("DD MMM YYYY hh:mm a");
  }
  return date;
};

export const getTimeInMillies = date => {
  let timeInMilles = "";
  if (date !== undefined) {
    timeInMilles = Moment(date, "YYYY-MM-DD").valueOf();
  }
  return timeInMilles;
};

export const getDuration = device => {
  let duration = "";
  if (device.claimedAt !== undefined) {
    duration = Moment(device.claimedAt).fromNow();
  }
  return duration;
};

export const getTodayDate = () => {
  const date = Moment().format("YYYY-MM-DD");
  return date;
};
