import React from "react";
import { Row, Col } from "antd";
import logo from "../../images/mobisoft_new_logo.svg";
import HeaderProfile from "../layouts/HeaderProfile";
const MainHeader = ({ user, onLogOut }) => {
  return (
    <Row gutter={24}>
      <Col span={12}>
        <img style={styles.logoStyle} src={logo} alt="Logo" />
      </Col>
      <Col span={12} align="right">
        {user ? (
          <HeaderProfile name={user.userName} onLogOut={onLogOut} />
        ) : null}
      </Col>
    </Row>
  );
};
export default MainHeader;

const styles = {
  deviceName: {
    fontSize: 18
  },
  logoStyle: {
    height: 40
  }
};
