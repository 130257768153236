import { Modal, message } from "antd";
import Strings from "../utils/Strings";

export const showSuccessAlert = (msg, onOkClick) => {
  Modal.success({
    title: Strings.success,
    content: msg,
    onOk() {
      onOkClick();
    }
  });
};

export const showErrorAlert = (msg, onOkClick) => {
  Modal.error({
    title: Strings.error,
    content: msg,
    onOk() {
      onOkClick();
    }
  });
};

export const showErrorMessage = msg => {
  message.error(msg);
};
export const showSuccessMessage = msg => {
  message.success(msg);
};

export const showDeleteWarning = onOkClick => {
  Modal.confirm({
    title: Strings.delete,
    content: Strings.deleteWarning,
    onOk() {
      onOkClick();
    },
    onCancel() {}
  });
};
