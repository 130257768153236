import React from "react";
import "antd/dist/antd.css";
import { Layout, Menu } from "antd";
import Strings from "../../utils/Strings";
import { Link } from "react-router-dom";
import "antd/lib/menu/style";
import "antd/lib/icon/style";
import "../../extendedStyles/menu.css";
import MenuIcon from "../common/MenuIcon";
const { Sider } = Layout;
const { Item } = Menu;

const SideBar = props => {
  const selectedKeys = [];
  if (
    props.keyValue.includes("inventoryDetail") ||
    props.keyValue.includes("editInventory") ||
    props.keyValue.includes("inventoryHistory")
  ) {
    selectedKeys.push("/inventoryList");
  } else if (props.keyValue.includes("deviceHistory")) {
    selectedKeys.push("/");
  } else {
    selectedKeys.push(props.keyValue);
  }
  return (
    <Sider theme="dark" collapsible={true}>
      {props.user.isAdmin ? (
        <Menu
          selectedKeys={selectedKeys}
          theme="dark"
          defaultSelectedKeys={[props.keyValue]}
        >
          <Item key="/">
            <MenuIcon iconName="mobile" />
            <span style={styles.sideBarTitle}>{Strings.deviceTracker}</span>
            <Link to="/" />
          </Item>

          <Item key="/users">
            <MenuIcon iconName="usergroup-add" />
            <span style={styles.sideBarTitle}>{Strings.users}</span>
            <Link to="/users" />
          </Item>
          <Item key={"/inventoryList"}>
            <MenuIcon iconName="database" />
            <span style={styles.sideBarTitle}>{Strings.inventoryList}</span>
            <Link to="/inventoryList" />
          </Item>
          <Item key="/addInventory">
            <MenuIcon iconName="file-add" />
            <span style={styles.sideBarTitle}>{Strings.addInventory}</span>
            <Link to="/addInventory" />
          </Item>
        </Menu>
      ) : (
        <Menu
          selectedKeys={selectedKeys}
          theme="dark"
          defaultSelectedKeys={[props.keyValue]}
        >
          <Item key="/">
            <MenuIcon iconName="mobile" />
            <span style={styles.sideBarTitle}>{Strings.deviceList}</span>
            <Link to="/" />
          </Item>
        </Menu>
      )}
    </Sider>
  );
};

const styles = {
  sideBarTitle: {
    fontSize: 18
  }
};
export default SideBar;
