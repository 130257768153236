const Colors = {
  white: "#ffffff",
  black: "#000000",
  appButtonColor: "#F74457",
  blackTwo: "#3A3A3A",
  lightBlue: "#2672CB",
  borderColor: "#979797",
  appColor: "#78BBDB",
  lightGray: "#E0E0E0",
  lightGreen: "#9ACD32",
  lightRed: "#FF6600",
  osNameBackround: "#BAE0FF",
  transparent: "transparent",
  blackTransparent: "#40000000",
  pressColor: "rgba(255, 255, 255, 0.16)",
  warmGray: "rgb(117,117,117)",
  gray: "#989898",
  transparentBlack: "rgba(0, 0, 0, 0.5)"
};

export default Colors;
