import React from "react";
import { Modal, Button, Row, Form, Col, Input } from "antd";
import Strings from "../../utils/Strings";
import Constants from "../../utils/Constants";
import FormSelect from "../common/FormSelect";
import { Formik } from "formik";
import PropTypes from "prop-types";
const { Item } = Form;
const InventoryFilterDialog = ({
  isVisible,
  toggleFilter,
  filterInventories
}) => {
  return (
    <Modal
      title={Strings.filter}
      visible={isVisible}
      onCancel={() => toggleFilter(false)}
      footer={null}
    >
      <InventoryFilterForm filterInventories={filterInventories} />
    </Modal>
  );
};

const InventoryFilterForm = ({ filterInventories }) => (
  <Formik
    onSubmit={(values, actions) => {
      filterInventories(values);
    }}
    render={props => (
      <InventoryFilterFormInner
        filterInventories={filterInventories}
        {...props}
      />
    )}
  />
);

const InventoryFilterFormInner = props => {
  const {
    values,
    handleSubmit,
    handleChange,
    setFieldValue,
    filterInventories
  } = props;

  return (
    <Form onSubmit={handleSubmit}>
      <FormSelect
        id="inventoryType"
        value={values.inventoryType}
        label={Strings.inventoryType}
        options={Constants.INVENTORY_TYPES}
        placeholder={Strings.inventoryTypePlaceholder}
        setFieldValue={setFieldValue}
      />
      <FormSelect
        id="inventoryStatus"
        value={values.inventoryStatus}
        label={Strings.inventoryStatus}
        options={Constants.INVENTORY_STATUS}
        placeholder={Strings.inventoryStatusPlaceholder}
        setFieldValue={setFieldValue}
      />
      <FormSelect
        id="inventoryAvailability"
        value={values.inventoryAvailability}
        label={Strings.availableDevices}
        options={Constants.AVAILABILITY_TYPES}
        placeholder={Strings.inventoryAvailabilityPlaceholder}
        setFieldValue={setFieldValue}
      />

      <FormSelect
        id="purchaseType"
        value={values.purchaseType}
        label={Strings.purchaseType}
        options={Constants.PURCHASE_TYPES}
        placeholder={Strings.purchaseTypePlaceholder}
        setFieldValue={setFieldValue}
      />

      <Item label={Strings.purchaseDate}>
        <Row>
          <Input
            type="date"
            name={"fromDate"}
            onChange={handleChange}
            style={styles.purchaseDate}
          />
          <Input
            type="date"
            name={"toDate"}
            onChange={handleChange}
            style={styles.purchaseDate}
          />
        </Row>
      </Item>
      <Col align="right">
        <Button
          style={styles.button}
          onClick={() => {
            filterInventories({});
            setFieldValue("inventoryStatus", undefined);
            setFieldValue("inventoryType", undefined);
            setFieldValue("fromDate", undefined);
            setFieldValue("toDate", undefined);
            setFieldValue("purchaseType", undefined);
          }}
        >
          {Strings.clear}
        </Button>
        <Button style={styles.button} type="primary" htmlType="submit">
          {Strings.apply}
        </Button>
      </Col>
    </Form>
  );
};

InventoryFilterFormInner.propTypes = {
  values: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired
};

InventoryFilterDialog.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  toggleFilter: PropTypes.func.isRequired,
  filterInventories: PropTypes.func.isRequired
};

export default InventoryFilterDialog;

const styles = {
  input: {
    marginBottom: 15
  },
  purchaseDate: {
    width: "45%",
    marginLeft: 10
  },
  button: {
    width: "20%",
    marginTop: 10,
    marginLeft: 15
  }
};
