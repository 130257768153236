import FirebaseDBConstants from "../utils/FirebaseDBConstants";
import FirebaseDBManager from "../firebase/FirebaseDBManager";
import { getTimeInMillies } from "../utils/DateUtils";
import Strings from "../utils/Strings";
import Moment from "moment";

export const getInventories = callBack => {
  FirebaseDBManager.database
    .child(FirebaseDBConstants.INVENTORIES_DB)
    .on("value", function(snapshot) {
      var inventories = [];
      if (snapshot.exists()) {
        snapshot.forEach(function(child) {
          inventories.push(child.val());
        });
      }
      callBack(inventories);
    });
};

export const getInventoryDetails = (
  inventoryId,
  successCallBack,
  errorCallBack
) => {
  FirebaseDBManager.database
    .child(FirebaseDBConstants.INVENTORIES_INFO_DB)
    .child(inventoryId)
    .on("value", function(snapshot) {
      if (snapshot.exists()) {
        successCallBack(snapshot.val());
      } else {
        errorCallBack(Strings.inventoryInfoNotFound);
      }
    });
};

export const getInventoryHistory = (inventoryId, callback) => {
  FirebaseDBManager.database
    .child(FirebaseDBConstants.INVENTORIES_HISTORY)
    .child(inventoryId)
    .on("value", function(snapshot) {
      const history = [];
      if (snapshot.exists()) {
        if (snapshot.exists()) {
          snapshot.forEach(function(child) {
            history.push(child.val());
          });
        }
      }
      callback(history);
    });
};

export const requestToRemoveListener = () => {
  FirebaseDBManager.database
    .child(FirebaseDBConstants.INVENTORIES_DB)
    .off("value");
};

export const requestToRemoveInventoryInfoListener = inventoryId => {
  FirebaseDBManager.database
    .child(FirebaseDBConstants.INVENTORIES_INFO_DB)
    .child(inventoryId)
    .off("value");
};

export const requestToRemoveInventoryHistoryListener = inventoryId => {
  FirebaseDBManager.database
    .child(FirebaseDBConstants.INVENTORIES_HISTORY)
    .child(inventoryId)
    .off("value");
};

export const requestToDeleteInventory = inventory => {
  var updates = {};
  updates[FirebaseDBConstants.INVENTORIES_DB + "/" + inventory.id] = null;
  updates[FirebaseDBConstants.INVENTORIES_INFO_DB + "/" + inventory.id] = null;
  updates[FirebaseDBConstants.INVENTORIES_HISTORY + "/" + inventory.id] = null;

  return new Promise((resolve, reject) => {
    FirebaseDBManager.database.update(updates, function(error) {
      if (error) {
        reject(error);
      } else {
        resolve();
      }
    });
  });
};

export const getSearchInventories = (text, inventories) => {
  const searchText = text.toLowerCase();
  try {
    return inventories.filter(inventory => {
      return (
        (inventory.macAddress !== undefined &&
          inventory.macAddress.toLowerCase().includes(searchText)) ||
        inventory.inventoryId.toLowerCase().includes(searchText) ||
        inventory.inventoryOS.toLowerCase().includes(searchText) ||
        inventory.manufacturerName.toLowerCase().includes(searchText) ||
        (inventory.projectName !== undefined &&
          inventory.projectName.toLowerCase().includes(searchText))
      );
    });
  } catch (error) {
    console.log(error);
    return inventories;
  }
};

export const getFilterInventories = (filter, inventories) => {
  try {
    const filteredInventories = filterInventories(filter, inventories);
    return filterInventoriesByDate(filter, filteredInventories);
  } catch (error) {
    console.log(error);
    return inventories;
  }
};

const filterInventoriesByDate = (filter, inventories) => {
  return inventories.filter(inventory => {
    if (filter.fromDate !== undefined) {
      const timeInMillies = getTimeInMillies(filter.fromDate);
      if (inventory.purchaseDate < timeInMillies) {
        return false;
      }
    }
    if (filter.toDate !== undefined) {
      const timeInMillies = getTimeInMillies(filter.toDate);
      if (inventory.purchaseDate > timeInMillies) {
        return false;
      }
    }
    return true;
  });
};

const filterInventories = (filter, inventories) => {
  const filterStatus = filter.inventoryStatus === "Active" ? true : false; //to check is active or not

  return inventories.filter(inventory => {
    const filterAvailability =
      filter.inventoryAvailability === Strings.allocatedDevices
        ? inventory.projectName
        : undefined; //to check asset is active or not

    if (
      filter.inventoryType !== undefined &&
      inventory.inventoryType !== filter.inventoryType
    ) {
      return false;
    }
    if (
      filter.inventoryStatus !== undefined &&
      inventory.isActive !== filterStatus
    ) {
      return false;
    }
    if (
      filter.purchaseType !== undefined &&
      inventory.purchaseType !== filter.purchaseType
    ) {
      return false;
    }
    if (
      filter.inventoryStatus !== undefined &&
      inventory.isActive !== filterStatus
    ) {
      return false;
    }
    if (
      filter.inventoryAvailability !== undefined &&
      inventory.projectName !== filterAvailability
    ) {
      return false;
    }

    return true;
  });
};

export const requestToAllocateInventory = (allocationDetails, inventoryId) => {
  allocationDetails.claimedAt = Moment().valueOf();
  var ref = FirebaseDBManager.database
    .child(FirebaseDBConstants.INVENTORIES_HISTORY)
    .child(inventoryId)
    .push();
  const id = ref.getKey();
  allocationDetails.id = id;
  var updates = {};
  updates[
    FirebaseDBConstants.INVENTORIES_HISTORY + "/" + inventoryId + "/" + id
  ] = allocationDetails;
  updates[
    FirebaseDBConstants.INVENTORIES_DB + "/" + inventoryId + "/" + "projectName"
  ] = allocationDetails.projectName;
  updates[
    FirebaseDBConstants.INVENTORIES_INFO_DB +
      "/" +
      inventoryId +
      "/" +
      "currentUserInfo"
  ] = allocationDetails;
  return new Promise((resolve, reject) => {
    FirebaseDBManager.database.update(updates, function(error) {
      if (error) {
        reject(error);
      } else {
        resolve();
      }
    });
  });
};

export const requestToAddInventoryHistory = (
  allocationDetails,
  inventoryId
) => {
  var ref = FirebaseDBManager.database
    .child(FirebaseDBConstants.INVENTORIES_HISTORY)
    .child(inventoryId)
    .push();
  const id = ref.getKey();
  allocationDetails.id = id;
  var updates = {};
  updates[
    FirebaseDBConstants.INVENTORIES_HISTORY + "/" + inventoryId + "/" + id
  ] = allocationDetails;
  return new Promise((resolve, reject) => {
    FirebaseDBManager.database.update(updates, function(error) {
      if (error) {
        reject(error);
      } else {
        resolve();
      }
    });
  });
};

export const requestToDeallocateInventory = (
  allocationDetails,
  inventoryId
) => {
  const relinquishAt = Moment().valueOf();
  var updates = {};
  updates[
    FirebaseDBConstants.INVENTORIES_HISTORY +
      "/" +
      inventoryId +
      "/" +
      allocationDetails.id +
      "/" +
      "relinquishAt"
  ] = relinquishAt;
  updates[
    FirebaseDBConstants.INVENTORIES_INFO_DB +
      "/" +
      inventoryId +
      "/" +
      "currentUserInfo"
  ] = null;
  updates[
    FirebaseDBConstants.INVENTORIES_DB + "/" + inventoryId + "/" + "projectName"
  ] = null;
  return new Promise((resolve, reject) => {
    FirebaseDBManager.database.update(updates, function(error) {
      if (error) {
        reject(error);
      } else {
        resolve();
      }
    });
  });
};
