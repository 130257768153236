import {
  INVENTORY_FILTER_VISIBLE,
  CLEAR_MESSAGES,
  INVENTORIES_FETCH_SUCCESS,
  INVENTORIES_FILTERED,
  CHANGE_INVENTORY_PAGE_NUMBER
} from "../actions/ActionTypes";
const INITIAL_STATE = {
  inventories: [],
  isFilterVisible: false,
  filterType: 0,
  isLoading: true,
  storedInventories: [],
  pageNumber: 0
};

export default (state = INITIAL_STATE, action) => {  
  switch (action.type) {
    case INVENTORIES_FETCH_SUCCESS:
      return {
        ...state,
        inventories: action.inventories,
        isLoading: false,
        storedInventories: action.inventories,
        pageNumber: action.pageNumber
      };
    case INVENTORY_FILTER_VISIBLE:
      return { ...state, isFilterVisible: action.payload };
    case CLEAR_MESSAGES:
      return {
        ...state,
        deviceDeletedError: undefined,
        deviceDeletedSuccess: undefined
      };
    case INVENTORIES_FILTERED:
      return {
        ...state,
        inventories: action.inventories,
        isFilterVisible: false,
        pageNumber: 1
      };
    case CHANGE_INVENTORY_PAGE_NUMBER:    
      return { ...state, pageNumber: action.number };
    default:
      return state;
  }
};
