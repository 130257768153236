import FirebaseDBConstants from "../utils/FirebaseDBConstants";
import FirebaseDBManager from "../firebase/FirebaseDBManager";
import firebase from "firebase";

export const requestToAddUser = user => {
  return new Promise((resolve, reject) => {
    firebase
      .auth()
      .createUserWithEmailAndPassword(user.email, user.password)
      .then(data => {
        resolve(saveToFirebaseDb(user, data.user.uid));
      })
      .catch(error => {
        reject(error.message);
      });
  });
};

export const requestToUpdateUser = user => {
  var updates = {};
  var baseRef = FirebaseDBConstants.USERS_DB + "/" + user.userId + "/";
  updates[baseRef + FirebaseDBConstants.USER_NAME] = user.userName;
  updates[baseRef + FirebaseDBConstants.IS_ADMIN] = user.isAdmin;
  return new Promise((resolve, reject) => {
    FirebaseDBManager.database.update(updates, function(error) {
      if (error) {
        reject(error);
      } else {
        resolve();
      }
    });
  });
};

const saveToFirebaseDb = (user, userId) => {
  return new Promise((resolve, reject) => {
    FirebaseDBManager.database
      .child(FirebaseDBConstants.USERS_DB)
      .child(userId)
      .set({
        userEmail: user.email,
        userId: userId,
        userName: user.name,
        isAdmin: false,
        isActive: true
      })
      .then(data => {
        resolve();
      })
      .catch(error => {
        reject(error.message);
      });
  });
};
