import React from "react";
import { Button } from "antd";
import PropTypes from "prop-types";
import { CSVLink } from "react-csv";
import { getDate } from "../../utils/DateUtils";
import Strings from "../../utils/Strings";
const size = "large";

const DownloadCSV = ({ inventories }) => {
  if (inventories === undefined || inventories.length === 0) {
    return null;
  }
  return (
    <CSVLink
      data={createCSVData(inventories)}
      filename={"MIDeviceTrackerReport.csv"}
    >
      <Button
        style={styles.downloadBtn}
        type="primary"
        shape="round"
        icon="download"
        size={size}
      >
        {Strings.downloadReport}
      </Button>
    </CSVLink>
  );
};
const csvHeader = [
  Strings.inventoryId,
  Strings.lanOrWifiMacAddress,
  Strings.projectName,
  Strings.purchaseDate,
  Strings.purchaseType,
  Strings.inventoryType,
  Strings.inventoryOS,
  Strings.companyName,
  Strings.status
];
const createCSVData = inventories => {
  const csvData = [];
  csvData.push(csvHeader);
  inventories.forEach(inventory => {
    const csvEntry = [
      inventory.inventoryId,
      inventory.macAddress,
      inventory.projectName,
      getDate(inventory.purchaseDate),
      inventory.purchaseType,
      inventory.inventoryType,
      inventory.inventoryOS,
      inventory.manufacturerName,
      inventory.isActive ? Strings.statusActive : Strings.statusInActive
    ];
    csvData.push(csvEntry);
  });
  return csvData;
};
const styles = {
  downloadBtn: {
    marginRight: 15
  }
};

DownloadCSV.propTypes = {
  inventories: PropTypes.array
};

export default DownloadCSV;
