import Strings from "./Strings";
import Constants from "./Constants";

export const handleError = error => {
  if (error.code !== undefined && error.code === Constants.PERMISSION_DENIED) {
    return Strings.userAdminDeleted;
  } else {
    return error.message;
  }
};
