import {
  getUsers,
  getSearchUsers,
  requestTodeleteUser,
  requestToRemoveListener
} from "../services/UserListService";
import {
  USERS_SUCCESS,
  USERS_FETCH,
  ADD_USER_VISIBLE,
  ADD_USER_SUCCESS,
  ADD_USER_ERROR,
  USER_DELETED_ERROR,
  USER_DELETED_SUCCESS,
  CLEAR_MESSAGES,
  EDIT_USER_VISIBLE
} from "./ActionTypes";
import {
  requestToAddUser,
  requestToUpdateUser
} from "../services/AddUserService";
import Strings from "../utils/Strings";
export const searchUsers = searchText => (dispatch, getState) => {
  const devicesState = getState().users;
  const searchedUsers = getSearchUsers(searchText, devicesState.storedUsers);
  dispatch({ type: USERS_SUCCESS, users: searchedUsers });
};

export const fetchUsers = () => dispatch => {
  return getUsers(users => {
    dispatch({ type: USERS_FETCH, users: users });
  });
};

export const removeListener = () => {
  requestToRemoveListener();
};

export const clearMessages = () => dispatch => {
  dispatch({
    type: CLEAR_MESSAGES
  });
};

export const toggleAddUser = isVisible => dispatch => {
  return dispatch({ type: ADD_USER_VISIBLE, isVisible: isVisible });
};

export const toggleEditUser = (isVisible, user) => dispatch => {
  return dispatch({
    type: EDIT_USER_VISIBLE,
    isVisible: isVisible,
    user: user
  });
};

export const deleteUser = user => async dispatch => {
  try {
    await requestTodeleteUser(user);
    dispatch({
      type: USER_DELETED_SUCCESS,
      userDeletedSuccess: Strings.userDeletedSuccess
    });
  } catch (error) {
    console.log(error);
    dispatch({ type: USER_DELETED_ERROR, userDeletedError: error });
  }
};

export const addUser = (user, actions) => async dispatch => {
  try {
    await requestToAddUser(user);
    actions.setSubmitting(false);
    actions.setValues({ email: "" });
    dispatch({
      type: ADD_USER_SUCCESS,
      addUserSuccess: Strings.addUserSuccess
    });
  } catch (error) {
    console.log(error);
    actions.setSubmitting(false);
    actions.setValues({ email: "" });
    actions.setTouched({});
    dispatch({ type: ADD_USER_ERROR, addUserError: error });
  }
};

export const updateUser = (user, actions) => async dispatch => {
  try {
    await requestToUpdateUser(user);
    actions.setSubmitting(false);
    actions.setValues({ email: "" });
    dispatch({
      type: ADD_USER_SUCCESS,
      addUserSuccess: Strings.userUpdateSuccess
    });
  } catch (error) {
    console.log(error);
    actions.setSubmitting(false);
    actions.setValues({ email: "" });
    actions.setTouched({});
    dispatch({ type: ADD_USER_ERROR, addUserError: error });
  }
};
