import React, { Component } from "react";
import { Layout } from "antd";
import "antd/dist/antd.css";
import DevicesList from "./DevicesList";
import UsersList from "./UsersList";
import MainHeader from "../components/layouts/MainHeader";
import SideBar from "../components/layouts/SideBar";
import { Switch, Route, withRouter } from "react-router-dom";
import Colors from "../utils/Colors";
import Strings from "../utils/Strings";
import LoginContainer from "../containers/LoginContainer";
import AddInventory from "../containers/AddInventory";
import EditInventory from "../containers/EditInventory";
import InventoryHistory from "../containers/InventoryHistory";
import DeviceTrackerHistory from "../containers/DeviceTrackerHistory";
import InventoryList from "../containers/InventoryList";
import InventoryDetail from "../containers/InventoryDetail";
import { withCookies } from "react-cookie";
import { connect } from "react-redux";
import {
  changeAunthenticationState,
  clearMessages,
  logOut
} from "../actions/AuthActions";

const { Content, Footer, Header } = Layout;
class MainDashboard extends Component {
  constructor(props) {
    super(props);
    this.checkUser();
  }

  render() {
    if (this.props.isLoggedIn) {
      return loggedInUserDashBoard(this.props);
    } else {
      return logInPage();
    }
  }

  checkUser() {
    const { cookies } = this.props;
    const user = cookies.get("user");
    if (!user) {
      this.props.changeAunthenticationState(false, user);
    } else {
      this.props.changeAunthenticationState(true, user);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.loginSuccess) {
      const { cookies } = this.props;
      cookies.set("user", nextProps.loginSuccess, { path: "/" });
      nextProps.clearMessages();
      nextProps.changeAunthenticationState(true, nextProps.loginSuccess);
    }
  }
}

const logInPage = () => {
  return (
    <Layout>
      <Content style={styles.loginContent}>
        <Switch>
          <Route path="/login" component={LoginContainer} />
          <Route path="*" component={LoginContainer} />
        </Switch>
      </Content>
    </Layout>
  );
};

const loggedInUserDashBoard = props => {
  const hash = window.location.hash;
  const key = hash.length === 0 ? "/" : "/" + hash.substring(1);

  const logOutCall = () => {
    const { cookies, logOut } = props;
    cookies.remove("user", { path: "/" });
    logOut();
  };
  const { user } = props;
  return (
    <Layout>
      <Header style={styles.header}>
        <MainHeader user={props.user} onLogOut={logOutCall} />
      </Header>
      <Layout style={styles.dashBoard} hasSider={true}>
        <SideBar keyValue={key} user={user} />
        <Content style={styles.mainContent}>
          <Switch>
            {user.isAdmin === true ? (
              <Switch>
                <Route path="/" exact={true} component={DevicesList} />
                <Route path="/users" component={UsersList} />
                <Route path="/inventoryList" component={InventoryList} />
                <Route
                  path="/addInventory"
                  component={AddInventory}
                  onEnter={() => window.location.reload()}
                />
                <Route
                  path="/editInventory/:inventoryId"
                  component={EditInventory}
                />
                <Route
                  path="/inventoryHistory/:inventoryId"
                  component={InventoryHistory}
                />
                <Route
                  path="/inventoryDetail/:inventoryId"
                  component={InventoryDetail}
                />
                <Route
                  path="/deviceHistory/:deviceId"
                  component={DeviceTrackerHistory}
                />
              </Switch>
            ) : (
              <Switch>
                <Route path="/" exact={true} component={DevicesList} />
                <Route
                  path="/deviceHistory/:deviceId"
                  component={DeviceTrackerHistory}
                />
              </Switch>
            )}
            <Route path="*" component={DevicesList} />
          </Switch>
        </Content>
      </Layout>
      <Footer style={styles.footer}>
        <span style={styles.footerText}>{Strings.footerText}</span>
      </Footer>
    </Layout>
  );
};

const mapStateToProps = (state, ownProps) => {
  state = state.auth;
  return {
    loginSuccess: state.loginSuccess,
    isLoggedIn: state.isLoggedIn,
    user: state.user,
    cookies: ownProps.cookies
  };
};

export default withCookies(
  withRouter(
    connect(
      mapStateToProps,
      {
        logOut,
        clearMessages,
        changeAunthenticationState
      }
    )(MainDashboard)
  )
);

const styles = {
  mainContent: {
    margin: "20px 30px",
    marginBottom: 140
  },
  loginContent: {
    flex: 1
  },
  dashBoard: {
    marginTop: 60,
    overflow: "auto",
    height: "100vh",
    width: "100%",
    position: "fixed",
    left: 0
  },
  header: {
    backgroundColor: Colors.black,
    paddingLeft: 10,
    position: "fixed",
    width: "100%",
    zIndex: 999
  },
  footerText: {
    textAlign: "center",
    color: Colors.black
  },
  footer: {
    position: "fixed",
    bottom: 0,
    width: "100%",
    backgroundColor: Colors.lightGray
  }
};
