import FirebaseDBConstants from "../utils/FirebaseDBConstants";
import FirebaseDBManager from "../firebase/FirebaseDBManager";
import Strings from "../utils/Strings";

export const requestToAddInventory = inventory => {
  const inventoryInfo = inventory.inventoryInfo;
  const purchaseInfo = inventory.purchaseInfo;
  return new Promise((resolve, reject) => {
    var ref = FirebaseDBManager.database
      .child(FirebaseDBConstants.INVENTORIES_DB)
      .push();
    const id = ref.getKey();
    ref
      .set({
        id: id,
        inventoryId: inventoryInfo.inventoryId,
        inventoryType: inventoryInfo.inventoryType,
        inventoryOS: inventoryInfo.inventoryOS,
        manufacturerName: inventoryInfo.manufacturerName,
        purchaseDate: purchaseInfo.purchaseDate,
        macAddress: inventoryInfo.lanMacAddress
          ? inventoryInfo.lanMacAddress
          : inventoryInfo.macAddress,
        purchaseType: purchaseInfo.purchaseType,
        isActive: true
      })
      .then(snapshot => {
        resolve(saveInventoryInfo(inventory, id));
      })
      .catch(error => {
        console.log(error);
        reject(error);
      });
  });
};

export const checkInventoryAlreadyExist = inventory => {
  const inventoryInfo = inventory.inventoryInfo;
  return new Promise((resolve, reject) => {
    FirebaseDBManager.database
      .child(FirebaseDBConstants.INVENTORIES_DB)
      .orderByChild(FirebaseDBConstants.MAC_ADDRESS)
      .equalTo(
        inventoryInfo.lanMacAddress
          ? inventoryInfo.lanMacAddress
          : inventoryInfo.macAddress
      )
      .once("value")
      .then(function(snapshot) {
        if (snapshot.exists()) {
          snapshot.forEach(function(child) {
            reject({ message: Strings.macAddressError });
          });
        } else {
          resolve(null);
        }
      })
      .catch(error => {
        reject(error);
      });
  });
};

export const requestToUpdateInventory = inventory => {
  const inventoryInfo = inventory.inventoryInfo;
  const purchaseInfo = inventory.purchaseInfo;
  const baseRef = FirebaseDBConstants.INVENTORIES_DB + "/" + inventory.id + "/";
  const updates = {};
  updates[baseRef + "inventoryId"] = inventoryInfo.inventoryId;
  updates[baseRef + "inventoryType"] = inventoryInfo.inventoryType;
  updates[baseRef + "inventoryOS"] = inventoryInfo.inventoryOS;
  updates[baseRef + "manufacturerName"] = inventoryInfo.manufacturerName;
  updates[baseRef + "purchaseDate"] = purchaseInfo.purchaseDate;
  updates[baseRef + "isActive"] = inventoryInfo.isActive;
  updates[baseRef + "purchaseType"] = purchaseInfo.purchaseType;
  updates[baseRef + "macAddress"] = inventoryInfo.lanMacAddress
    ? inventoryInfo.lanMacAddress
    : inventoryInfo.macAddress;

  return new Promise((resolve, reject) => {
    FirebaseDBManager.database.update(updates, function(error) {
      if (error) {
        console.log("error");
        reject(error);
      } else {
        resolve(saveInventoryInfo(inventory, inventory.id));
      }
    });
  });
};

const saveInventoryInfo = (inventory, id) => {
  return new Promise((resolve, reject) => {
    inventory.id = id;
    FirebaseDBManager.database
      .child(FirebaseDBConstants.INVENTORIES_INFO_DB)
      .child(id)
      .set(inventory)
      .then(snapshot => {
        resolve();
      })
      .catch(error => {
        console.log(error);
        reject(error);
      });
  });
};
