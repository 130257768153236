import React, { Component } from "react";
import FirebaseDBManager from "../firebase/FirebaseDBManager";
import { List, Button, Col } from "antd";
import "antd/dist/antd.css";
import InventoryhistoryCard from "../components/listItems/InventoryHistoryCard";
import InventoryHistoryHeader from "../components/listItems/InventoryHistoryHeader";
import {
  fetchInventoryHistory,
  clearMessages,
  toggleAllocateInventory,
  addInventoryHistory,
  fetchUsers,
  removeHistoryListener
} from "../actions/InventoryDetailActions";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import "../extendedStyles/button.css";
import AllocateInventoryDialog from "../components/dialogs/AllocateInventoryDialog";
import Strings from "../utils/Strings";
import { showErrorAlert } from "../utils/ShowAlertDialog";
const { Item } = List;
const size = "large";
class Inventoryhistory extends Component {
  constructor() {
    super();
    this.addInventoryHistory = this.addInventoryHistory.bind(this);
  }
  componentDidMount() {
    FirebaseDBManager.init();
    const {
      match: { params }
    } = this.props;
    if (params.inventoryId) {
      this.props.fetchInventoryHistory(params.inventoryId);
    } else {
      this.props.history.goBack();
    }
    this.props.fetchUsers();
  }

  addInventoryHistory(allocationDetail, actions) {
    const {
      match: { params }
    } = this.props;
    this.props.addInventoryHistory(
      allocationDetail,
      params.inventoryId,
      actions
    );
  }

  componentWillUnmount() {
    const {
      match: { params }
    } = this.props;
    if (params.inventoryId) {
      removeHistoryListener(params.inventoryId);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.inventoryHistoryError) {
      showErrorAlert(nextProps.inventoryHistoryError, () => {
        nextProps.clearMessages();
        this.props.history.goBack();
      });
    }
  }

  render() {
    return (
      <div>
        <Col align="right">
          <Button
            size={size}
            style={styles.button}
            type="primary"
            icon="user-add"
            shape={"round"}
            onClick={() => {
              this.props.toggleAllocateInventory(true);
            }}
          >
            {Strings.addHistory}
          </Button>
        </Col>

        <List
          header={<InventoryHistoryHeader />}
          itemLayout="vertical"
          bordered={false}
          loading={this.props.isHistoryLoading}
          pagination={true}
          dataSource={this.props.inventoryHistory}
          renderItem={item => (
            <Item style={styles.deviceItem}>
              <InventoryhistoryCard inventoryHistory={item} />
            </Item>
          )}
        />
        <AllocateInventoryDialog
          {...this.props}
          users={this.props.users}
          inventory={this.props.inventory}
          isHistory
          onAllocateInventory={this.addInventoryHistory}
          isVisible={this.props.isAllocateInventoryVisible}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  state = state.inventoryDetail;
  return {
    inventory: state.inventory,
    inventoryHistory: state.inventoryHistory,
    isAllocateInventoryVisible: state.isAllocateInventoryVisible,
    inventoryHistoryError: state.inventoryHistoryError,
    users: state.users,
    allocateInventorySuccess: state.allocateInventorySuccess,
    allocateInventoryError: state.allocateInventoryError,
    isHistoryLoading: state.isHistoryLoading
  };
};

export default connect(
  mapStateToProps,
  {
    toggleAllocateInventory,
    fetchInventoryHistory,
    clearMessages,
    addInventoryHistory,
    fetchUsers
  }
)(Inventoryhistory);

const styles = {
  deviceItem: {
    backgroundColor: "white",
    padding: 20
  }
};

Inventoryhistory.propTypes = {
  inventoryHistory: PropTypes.array.isRequired,
  inventoryHistoryError: PropTypes.any
};
